

export const Dkl475_decoder = (v) => {
    if (!v) {
        return null;
    }
//(uint32_t)((MAJOR_VERSION << 24u) | (MINOR_VERSION << 16u) | PATCH_VERSION)
    // v.v.v

    let major = (v >> 24) & 0xFF;
    let minor = (v >> 16) & 0xFF;
    let patch = v & 0xFFFF;
    return `${major}.${minor}.${patch}`
}