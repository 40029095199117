import React, {useEffect, useState} from "react";
import Helper from "../../library/Helper";
import AccessControlled from "./AccessControlled";
import Permissions from "../../Permissions";
import {RoleAccessType} from "../../domain/SystemTypes";

const RolePermissionListItem = ({ item , index, total_length, onSelect, onRemove, onAssign, editing}) => {

    const [accessTypeId, setAccessTypeId] = useState(item.access_type_id)

    useEffect(() => {
        item.access_type_id = accessTypeId
        if (item.allocated) {
            item.updated = true
        }
    }, [accessTypeId])

    useEffect(() => {
        setAccessTypeId(item.access_type_id)
    }, [editing])

    if (!item) {
        return null
    }

    let cn = item.adding ? "list-item-selected" : "list-item" ;

    return (
        <div className={cn} onClick={() => onSelect?.(item)}>
            <div className={"list-body"} style={{width: 'fit-content'}}>
                <div className={"list-title"} >
                    <div><span className="material-icons" style={{color: 'var(--std-secondary-blue)', display: 'inline-block', width: '40px'}}>admin_panel_settings</span><span style={{verticalAlign: '4px'}}>{item.function_name}</span></div>
                </div>
                <div className={"list-summary"} >
                    <div style={{display: 'inline-block'}}>
                        <span>{item.function_description}</span>
                    </div>
                </div>
                <div className={"list-footer"} style={{fontSize: '16px', color: 'var(--std-secondary-orange)'}}>
                    {(!editing) &&<span >[{item.access_type}]</span>}
                    {(editing && (item.adding || item.allocated)) &&
                        <div style={{display: 'inline-block'}}>
                            <div
                                className={accessTypeId === RoleAccessType.AccessNone ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => {setAccessTypeId(RoleAccessType.AccessNone)}}>
                                    <span className="material-symbols-outlined"
                                         style={{
                                             marginRight: '10px',
                                             verticalAlign: '-6px'
                                         }}>map</span>None
                            </div>
                            <div
                                className={accessTypeId === RoleAccessType.AccessRead ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => {setAccessTypeId(RoleAccessType.AccessRead)}}><span className="material-symbols-outlined"
                                                                         style={{
                                                                             marginRight: '10px',
                                                                             verticalAlign: '-6px'
                                                                         }}>table_rows</span>Read
                            </div>
                            <div
                                className={accessTypeId === RoleAccessType.AccessWrite ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => {setAccessTypeId(RoleAccessType.AccessWrite)}}><span className="material-symbols-outlined"
                                                                         style={{
                                                                             marginRight: '10px',
                                                                             verticalAlign: '-6px'
                                                                         }}>table_rows</span>Edit
                            </div>
                            <div
                                className={accessTypeId === RoleAccessType.AccessAdmin ? "segment-select-item-selected " : "segment-select-item"}
                                onClick={() => {setAccessTypeId(RoleAccessType.AccessAdmin)}}><span className="material-symbols-outlined"
                                                                         style={{
                                                                             marginRight: '10px',
                                                                             verticalAlign: '-6px'
                                                                         }}>table_rows</span>Admin
                            </div>
                        </div>
                    }
                </div>

                {(editing) &&
                    <>
                        {(item.allocated) &&
                            <AccessControlled access={Permissions.AdminViewPermissions}>
                                <button className={"button-small button-cancel"} onClick={() => onRemove?.(item)}>
                                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>delete</span>Remove
                                </button>
                            </AccessControlled>
                        }

                        {(!item.allocated) &&
                            <>
                                {(!item.adding) &&
                                    <AccessControlled access={Permissions.AdminViewPermissions}>
                                        <button className={"button-small button-save"} onClick={() => onAssign?.(item, true)}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>add</span>Assign
                                        </button>
                                    </AccessControlled>
                                }

                                {(item.adding) &&
                                <AccessControlled access={Permissions.AdminViewPermissions}>
                                    <button className={`button-small button-cancel`} onClick={() => onAssign?.(item, false)}>
                                        <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>cancel</span>Cancel allocation
                                    </button>
                                </AccessControlled>
                            }

                        </>
                    }
                    </>
                }
            </div>
        </div>
    )
}


export default RolePermissionListItem;