

export const DisplaySizeClass = {
    MobilePortrait: 1,
    MobileLandscape: 2,
    TabletPortrait: 3,
    TabletLandscape: 4,
    Desktop: 5,
    label: (size) => {
        switch (size) {
            case DisplaySizeClass.MobilePortrait:
                return 'Mobile Portrait';
            case DisplaySizeClass.MobileLandscape:
                return 'Mobile Landscape';
            case DisplaySizeClass.TabletPortrait:
                return 'Tablet Portrait';
            case DisplaySizeClass.TabletLandscape:
                return 'Tablet Landscape';
            case DisplaySizeClass.Desktop:
                return 'Desktop';
            default:
                return 'Unknown';
        }
    }
}