import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (hasError) {
            navigate('/error');
        }
    }, [hasError, navigate]);

    const getDerivedStateFromError = (error) => {
        setHasError(true);
    };

    const componentDidCatch = (error, errorInfo) => {
        console.error("ErrorBoundary caught an error", error, errorInfo);
    };

    if (hasError) {
        return null;
    }

    return children;
};

export default ErrorBoundary;