import Helper from "../../library/Helper";
import React from "react";
import {ProductTypes} from "../../domain/SystemTypes";
import duo_logo from "../../assets/Duo-Hybrid-Black.png";
import trio_logo from "../../assets/Trio-Hybrid-Black.png";
import classic_logo from "../../assets/TCL-Black.png";


const SimProviderStatusSmallListItem = ({ item, index, total_length, onSelectSimProviderStatus}) => {

    if (!item) {
        return null
    }

    return (
        <div className={"list-item"} onClick={() => onSelectSimProviderStatus?.(item)}>
            <div className={"list-body"} >
                <div className={"list-title"} >
                    <div className={"list-prominent-hdr"}><span style={{verticalAlign: '4px'}}>{item.origin}</span></div>
                </div>
                <div className={"list-summary"}>
                    <div style={{verticalAlign: 'top'}}>
                        <div style={{color: 'black', marginTop: '5px', display: 'inline-block', fontSize : '32px', width : '80px'}}>{item.status_count}</div>

                        <div style={{color: 'var(--std-secondary-blue)', display: 'inline-block', fontSize : '32px'}}>{item.status}</div>

                    </div>
                </div>
                <div className={"list-footer"}>
                </div>
            </div>
            <div className={"list-nav"}>→</div>
        </div>
    )
}


export default SimProviderStatusSmallListItem;