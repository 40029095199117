const ProductSmallListItem = ({ item , index, total_length, onSelect}) => {

    if (!item) {
        return null
    }

    return (
        <div className={"list-item"} onClick={() => onSelect?.(item)}>
            <div className={"list-body"} >
                <div className={"list-title"} >{item.productname}</div>
                <div className={"list-summary"} >{item.productdescription}</div>
                <div className={"list-footer"} >Family: {item.product_family} | Type: {item.product_type} | Installs : {item.lift_count}</div>
            </div>
            <div className={"list-nav"} >→</div>
        </div>
    )
}


export default ProductSmallListItem;