import {
    los_buf_get_buf,
    los_buf_get_i16,
    los_buf_get_u16,
    los_buf_get_u32,
    los_buf_get_u8,
    los_fletch
} from "./common_extractors";


const LiftOSConstants = {
    liftOsMsgStartMarker         : 0x55,
    liftMessageV1HeaderSizeNoMsg : 21,
    liftDataRecordV1             : 1,
    otExtAddressSize             : 8,
    maxLiftMsgContentSize        : 800
}

const LiftOSMessageStatus = {
    msgNoData          : 1,
    msgMissingSTX      : 2,
    msgInvalidSize     : 3,
    msgInvalidVersion  : 4,
    msgFailedCRC       : 5,
    msgValidated       : 6
}

const lift_os_message_decoder = (message) => {
    if (message === null || message.length === 0) {
        return {status: LiftOSMessageStatus.msgNoData, message: null};
    }

    if (message[0] !== LiftOSConstants.liftOsMsgStartMarker) {
        return {status: LiftOSMessageStatus.msgMissingSTX, message: null};
    }

    if (message.length < (LiftOSConstants.liftMessageV1HeaderSizeNoMsg + 2)) {
        return {status: LiftOSMessageStatus.msgInvalidSize, message: null};
    }

    if (message[1] !== LiftOSConstants.liftDataRecordV1) {
        return {status: LiftOSMessageStatus.msgInvalidVersion, message: null};
    }

    const crc = los_fletch(message, message.length - 2);
    if (message[message.length - 2] !== crc[0] && message[message.length - 1] !== crc[1]) {
        return {status: LiftOSMessageStatus.msgFailedCRC, message: null};
    }

    let record_version = los_buf_get_u8(1, message) ;
    let record_size = los_buf_get_u16(record_version.offset, message) ;
    let message_size = los_buf_get_u16(record_size.offset, message) ;
    let message_seq = los_buf_get_u32(message_size.offset, message) ;
    let message_type = los_buf_get_u16(message_seq.offset, message) ;
    let message_flags = los_buf_get_u16(message_type.offset, message) ;
    let originator = los_buf_get_buf(message_flags.offset, message, LiftOSConstants.otExtAddressSize, LiftOSConstants.otExtAddressSize) ;
    let content = los_buf_get_buf(originator.offset, message, message_size.value, LiftOSConstants.maxLiftMsgContentSize) ;
    return {
        status: LiftOSMessageStatus.msgValidated,
        message: {
            record_version: record_version.value,
            record_size: record_size.value,
            message_size: message_size.value,
            message_seq: message_seq.value,
            message_type: message_type.value,
            message_flags: message_flags.value,
            originator: originator.value,
            content: content.value
        }
    };
}





export {
    lift_os_message_decoder,
    LiftOSMessageStatus,
    LiftOSConstants
}