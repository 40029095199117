import LinkBuilder from "../../domain/LinkBuilder";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Lifts from "../../api/liftApi";
import HttpCodes from "../../library/HttpCodes";
import Helper from "../../library/Helper";
import {ConnectionStatus} from "../../library/connectionStatus";
import {ProductTypes} from "../../domain/SystemTypes";

import duo_logo from "../../assets/Duo-Hybrid-White.png";
import trio_logo from "../../assets/Trio-Hybrid-White.png";
import classic_logo from "../../assets/TCL-White.png";

export const DeviceHomeTile = ({link, onRemove}) => {
    const gotopage = useNavigate() ;

    const [voltage, setVoltage] = useState(0) ;
    const [lowVoltage, setLowVoltage] = useState(null) ;
    const [highVoltage, setHighVoltage] = useState(null) ;
    const [lastUpdated, setLastUpdated] = useState(null) ;
    const [safety, setSafety] = useState(null) ;
    const [active, setActive] = useState(false) ;
    const [productType, setProductType] = useState(null) ;
    const [error, setError] = useState(null) ;

    useEffect(() => {
        refreshState() ;
        const interval = setInterval(() => {
            // refreshState() ;
        }, 60000 * 5);
        return () => clearInterval(interval);
    }, []);

    const refreshState = async () => {
        try {
            let state = await Lifts.deviceHomeLinkStatus(link.device_id) ;
            if (state.status === HttpCodes.HttpOK) {
                setVoltage(state.data.voltage / 1000) ;
                setLastUpdated(state.data.last_data_update) ;
                setActive(state.data.connection_status_id === ConnectionStatus.Active) ;
                setSafety(state.data.safety_chain_desc) ;
                setProductType(state.data.product_type_id)
                setLowVoltage(state.data.vmin ? state.data.vmin / 1000: null) ;
                setHighVoltage(state.data.vmax ? state.data.vmax / 1000 : null) ;
                setError(null) ;
            }
            else if (state.status === HttpCodes.HttpNotFound) {
                console.log("Data not found for device: ", link.device_id)
                setError("No Data Available")
                onRemove(link) ;
            }
            else {
                setError("Unable to refresh state")
            }
        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
        }
    }

    const selectLink = (l) => {
        LinkBuilder.gotoLink(gotopage, l) ;
    }

    let st = (voltage < 22 || safety) ? 'home-item home-inerror' : (active ? 'home-item home-active' : 'home-item home-inactive') ;

    return (
        <div className={st} >
            <div className="pill-body" onClick={() => selectLink(link)}>
                <div className="home-item-title">{link.title}</div>

                {!error && active && <div style={{textAlign: 'left', marginTop: '5px', fontStyle: 'italic'}}>
                    <small>{link.description}</small></div>}
                {!error && !active &&
                    <div style={{textAlign: 'center', marginTop: '5px', fontStyle: 'italic'}}><small>No Recent Data</small></div>}
                {error &&
                    <div style={{textAlign: 'center', marginTop: '5px', fontStyle: 'italic'}}><small>{error}</small></div>}
                <div style={{textAlign: 'center', marginTop: '5px', fontWeight: 'bold', fontSize: '24px'}}>{voltage}V</div>
                {(lowVoltage && highVoltage) &&
                    <div>
                        <div style={{display: 'inline-block', fontStyle: 'italic', fontSize: '12px'}}>(L) {lowVoltage}V</div>
                        <span className="material-symbols-outlined"
                              style={{fontSize: '10px', marginLeft: '5px', marginRight: '5px'}}>east</span>
                        <div style={{display: 'inline-block', fontStyle: 'italic', fontSize: '12px'}}>{highVoltage}V
                            (H)
                        </div>
                    </div>
                }

                {(!error && safety) && <div style={{textAlign: 'center', marginTop: '5px', fontStyle: 'italic'}}><small>{safety}</small></div>}
                <div style={{textAlign: 'center', marginTop: '3px', fontStyle: 'italic'}}>
                    <small>Updated: {Helper.dbTimeToDisplayTime(lastUpdated)}</small>
                </div>
            </div>

            <div className="pill-footer">
                <div style={{display: 'inline-block', float: 'left'}}><span className="material-icons" style={{fontSize: '16pt', marginRight: '3px', verticalAlign : '-6px'}} onClick={() => onRemove(link)}>cancel</span></div>
                <div style={{display: 'inline-block'}}>
                    {(productType === ProductTypes.Duo) && <img className="logo" style={{width: '70px', verticalAlign: 'top'}} src={duo_logo} alt="Stiltz - Product" />}
                    {(productType === ProductTypes.Trio) && <img className="logo" style={{width: '70px', verticalAlign: '-top'}} src={trio_logo} alt="Stiltz - Product" />}
                    {(productType === ProductTypes.Carer) && <img className="logo" style={{width: '100px', verticalAlign: '-top'}} src={classic_logo} alt="Stiltz - Product" />}
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    {/*<div className="material-icons" style={{fontSize: '16pt', marginRight: '3px', verticalAlign : '-6px'}} onClick={() => {}}>preview</div>*/}
                    <div className="material-icons" style={{fontSize: '16pt', marginRight: '3px', verticalAlign : '-6px'}} onClick={refreshState}>refresh</div>
                </div>
            </div>
        </div>
    )
}