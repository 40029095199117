import Helper from "../../library/Helper";
import React from "react";
import {ProductTypes} from "../../domain/SystemTypes";
import duo_logo from "../../assets/Duo-Hybrid-Black.png";
import trio_logo from "../../assets/Trio-Hybrid-Black.png";
import classic_logo from "../../assets/TCL-Black.png";


const SearchSmallListItem = ({ item, statusIcons , index, total_length, onSelect}) => {

    if (!item) {
        return null
    }


    return (
        <div className={"list-item"} onClick={() => onSelect?.(item)}>
            <div className={"list-body"} >
                <div className={"list-title"} >
                    <div>{statusIcons.map((icon, idx) => {return icon})}<span style={{verticalAlign: '4px'}}>{item.display_name}</span></div>
                </div>
                <div className={"list-summary"}>
                    <div style={{display: 'inline-block'}}>
                        <div style={{display: 'inline-block'}}>
                            {(!item.productname) && <div style={{marginBottom: '10px', color: 'red'}}>No product recorded</div>}
                            {(item.product_type_id === ProductTypes.Duo) && <img className="logo" style={{width: '90px', verticalAlign: 'top'}} src={duo_logo} alt="Stiltz - Product"/>}
                            {(item.product_type_id === ProductTypes.Trio) && <img className="logo" style={{width: '90px', verticalAlign: '-top'}} src={trio_logo} alt="Stiltz - Product"/>}
                            {(item.product_type_id === ProductTypes.Carer) && <img className="logo" style={{width: '120px', verticalAlign: '-top'}} src={classic_logo} alt="Stiltz - Product"/>}
                            {(item.productname) && <div style={{marginBottom: '10px'}}>{item.productname || "- Product Missing -"}</div>}

                            <div><small>Serial # = {item.serial_number || "unspecified"}</small></div>
                        </div>
                        <div style={{verticalAlign: 'top', display: 'inline-block'}}>
                            <div style={{display: 'inline-block', paddingLeft: '10px'}}>
                                <b >Customer</b>
                                {(item.customer_id) &&
                                    <>
                                        {(item.cust_contact_name) && <div className="lift-address-text">{item.cust_contact_name}</div>}
                                        {(item.cust_addr_line1) && <div className="lift-address-text">{item.cust_addr_line1}</div>}
                                        {(item.cust_addr_line2) && <div className="lift-address-text">{item.cust_addr_line2}</div>}
                                        {(item.cust_addr_line3) && <div className="lift-address-text">{item.cust_addr_line3}</div>}
                                        {(item.cust_addr_town) && <div className="lift-address-text">{item.cust_addr_town}</div>}
                                        {(item.cust_addr_postcode) && <div className="lift-address-text">{item.cust_addr_postcode}</div>}
                                    </>
                                }

                                {(!item.customer_id) &&
                                    <div className="lift-address-text">- Not specified -</div>
                                }

                            </div>
                        </div>
                    </div>

                </div>
                <div className={"list-footer"}>
                    <div style={{color: 'black', marginTop: '5px'}}>{item.connection_status}</div>
                    <div style={{marginBottom: '10px'}}>Last Data {Helper.dbTimeToDisplayTime(item.last_data_update)}</div>
                    {(item.common_safety_desc) &&
                        <div>
                            <div style={{color: 'red', marginTop: '5px', fontSize: '14px'}}>{item.common_safety_desc}</div>
                        </div>
                    }
                </div>
            </div>
            <div className={"list-nav"}>→</div>
        </div>
    )
}


export default SearchSmallListItem;