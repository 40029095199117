import {useContext} from "react";
import {AuthContext} from "../../ResponsiveApp";
import {RoleAccessType} from "../../domain/SystemTypes";


const AccessControlled = ({ children, admin_only, access, minAccessLevel = RoleAccessType.AccessRead, deniedView = null }) => {
    const { authorisation, hasMinimumAccess} = useContext(AuthContext)

    const hasPermission = (admin_only && authorisation.isAdmin) || hasMinimumAccess(access, minAccessLevel) ;
    return hasPermission ? children : deniedView;
}

export default AccessControlled;

