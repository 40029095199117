import React, {useContext} from "react";
import {DisplayContext} from "../../../ResponsiveApp";
import {LiftDataContext} from "../LiftDataNavigatorContext";
import Helper from "../../../library/Helper";


const LiftStatusDataSummary = () => {
    const display = useContext(DisplayContext)
    const {liftData, dataViewSet, lift} = useContext(LiftDataContext) ;

    return (
        <div>
            <h2>Lift Status Overview</h2>
            <div>Got Lift Data {liftData ? "Yes" : "No"}</div>
            <div>Got Lift {lift ? "Yes" : "No"}</div>
            <div>Got Data View Set {dataViewSet ? "Yes" : "No"}</div>
            <div>Total Records {dataViewSet.total_records}</div>
            <div>Current Record {dataViewSet.current_record}</div>
            <div>Current DataPoint {Helper.dbTimeToDisplayTime(dataViewSet.current?.received_at)}</div>
        </div>
    )
}

export default LiftStatusDataSummary;