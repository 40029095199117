import React from "react";
import Helper from "../../library/Helper";

const UserSmallListItem = ({ item , index, total_length, onSelect}) => {

    if (!item) {
        return null
    }

    return (
        <div className={"list-item"} onClick={() => onSelect?.(item)}>
            <div className={"list-body"} >
                <div className={"list-title"} >
                    {item.is_admin && <div><span className="material-icons" style={{color: 'var(--std-secondary-blue)', display: 'inline-block', width: '40px'}}>admin_panel_settings</span><span style={{verticalAlign: '4px'}}>{item.firstname}&nbsp;{item.surname}</span></div>}
                    {(!item.is_admin) && <div>{item.firstname}&nbsp;{item.surname}</div>}
                </div>
                <div className={"list-summary"}>
                    <div style={{display: 'inline-block'}}>
                        <span className="material-icons" style={{color: 'var(--std-primary-green)', display: 'inline-block', width: '40px'}}>email_disabled</span>
                        <span style={{verticalAlign: '8px'}}>{item.emailaddress}</span>
                    </div>
                </div>
                <div className={"list-footer"}>
                    Created: {Helper.dbDateToDisplayTime(item.created)}
                    <span style={{float: 'right'}}>Last Login: {Helper.dbDateToDisplayTime(item.last_login)}</span>
                </div>
            </div>
            <div className={"list-nav"}>→</div>
        </div>
    )
}


export default UserSmallListItem;