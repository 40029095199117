import {SimHighDataUsageTile} from "./SimHighDataUsageTile";
import LiftDeviceSimDataUsage from "./LiftDeviceSimDataUsage";
import React, {useEffect, useState} from "react";
import LiftDataNavigatorContext from "../lift/LiftDataNavigatorContext";
import Permissions from "../../Permissions";
import NoAccess from "../NoAccess";
import AccessControlled from "../users/AccessControlled";
import {RoleAccessType} from "../../domain/SystemTypes";


export const SimDataMonitoringPanel = () => {
    const [device, setDevice] = useState(null) ;


    return (
        <div>
            <SimHighDataUsageTile max_rows={10} onSelectDevice={setDevice}/>

            {device &&
                <LiftDataNavigatorContext device_id={device.device_id} load_data={false}>
                    <AccessControlled access={Permissions.SIMViewDataUsage} minAccessLevel={RoleAccessType.AccessRead} deniedView={<NoAccess/>}>
                        <LiftDeviceSimDataUsage sim_number={device.sim_number} sim_data_limit={50_000_000}/>
                    </AccessControlled>
                </LiftDataNavigatorContext>
            }
        </div>
    )
}