import {Dkl475_decoder} from "./field_decoders/dkl475_decoder";
import {DKEncoderCount_decoder} from "./field_decoders/dkEncoderCount_decoder_decoder";

const DiagnosticKeys = {
    DKF301Version                      :{key : 1, label : 'DKF301Version', decoder: Dkl475_decoder},
    DKL475Version                      :{key : 2, label : 'DKL475Version', decoder: Dkl475_decoder},
    DKLevelVersion                     :{key : 3, label : 'DKLevelVersion', decoder: Dkl475_decoder},
    DKPotVersion                       :{key : 4, label : 'DKPotVersion', decoder: Dkl475_decoder},
    DKGSMImei                          :{key : 5, label : 'DKGSMImei'},
    DKGSMSim                           :{key : 6, label : 'DKGSMSim'},
    DKEnvironment                      :{key : 7, label : 'DKEnvironment'},
    DKMessageFormat                    :{key : 8, label : 'DKMessageFormat'},
    DKTransmitType                     :{key : 9, label : 'DKTransmitType'},
    DKAdcA                             :{key : 10, label : 'DKAdcA'},
    DKAdcB                             :{key : 11, label : 'DKAdcB'},
    DKAdcC                             :{key : 12, label : 'DKAdcC'},
    DKFaultAdcA                        :{key : 13, label : 'DKFaultAdcA'},
    DKFaultAdcB                        :{key : 14, label : 'DKFaultAdcB'},
    DKFaultAdcC                        :{key : 15, label : 'DKFaultAdcC'},
    DKF301StopReason                   :{key : 16, label : 'DKF301StopReason'},
    DKL475StopReason                   :{key : 17, label : 'DKL475StopReason'},
    DKAdcM1                            :{key : 18, label : 'DKAdcM1'},
    DKAdcM2                            :{key : 19, label : 'DKAdcM2'},
    DKAdcM3                            :{key : 20, label : 'DKAdcM3'},
    DKGSMMessageNumber                 :{key : 21, label : 'DKGSMMessageNumber'},
    DKHeartbeatFlag                    :{key : 22, label : 'DKHeartbeatFlag'},
    DKOutputs                          :{key : 23, label : 'DKOutputs'},
    DKCopStatus                        :{key : 24, label : 'DKCopStatus'},
    DKBuzzerState                      :{key : 25, label : 'DKBuzzerState'},
    DKLiftAngle                        :{key : 26, label : 'DKLiftAngle'},
    DKZeroAngSpRead                    :{key : 27, label : 'DKZeroAngSpRead'},
    DKZeroAngSpSaved                   :{key : 28, label : 'DKZeroAngSpSaved'},
    DKSlidingPotValue                  :{key : 29, label : 'DKSlidingPotValue'},
    DKMotorGearSpeedSelected           :{key : 30, label : 'DKMotorGearSpeedSelected'},
    DKWeightSentToF301                 :{key : 31, label : 'DKWeightSentToF301'},
    DKConfirmWeightPreMove             :{key : 32, label : 'DKConfirmWeightPreMove'},
    DKBadTransmissionPreMove           :{key : 33, label : 'DKBadTransmissionPreMove'},
    DKGearSelectedGoingUP              :{key : 34, label : 'DKGearSelectedGoingUP'},
    DKGearSelectedGoingDown            :{key : 35, label : 'DKGearSelectedGoingDown'},
    DKEncoderCountM1                   :{key : 36, label : 'DKEncoderCountM1', decoder: DKEncoderCount_decoder},
    DKEncoderCountM2                   :{key : 37, label : 'DKEncoderCountM2', decoder: DKEncoderCount_decoder},
    DKCurrentBatteryVoltage            :{key : 38, label : 'DKCurrentBatteryVoltage'},
    DKFaultBatteryVoltage              :{key : 39, label : 'DKFaultBatteryVoltage'},
    DKFinalCorrection                  :{key : 40, label : 'DKFinalCorrection'},
    DKCorrectedBasedOn                 :{key : 41, label : 'DKCorrectedBasedOn'},
    DKEncoderCorrection                :{key : 42, label : 'DKEncoderCorrection'},
    DKDriftLargePositiveAngle          :{key : 43, label : 'DKDriftLargePositiveAngle'},
    DKDriftMediumPositiveAngle         :{key : 44, label : 'DKDriftMediumPositiveAngle'},
    DKDriftSmallPositiveAngle          :{key : 45, label : 'DKDriftSmallPositiveAngle'},
    DKDriftSmallNegativeAngle          :{key : 46, label : 'DKDriftSmallNegativeAngle'},
    DKDriftMediumNegativeAngle         :{key : 47, label : 'DKDriftMediumNegativeAngle'},
    DKDriftLargeNegativeAngle          :{key : 48, label : 'DKDriftLargeNegativeAngle'},
    DKEncoderErrorAtCorrection         :{key : 49, label : 'DKEncoderErrorAtCorrection'},
    DKEncoder1Backup                   :{key : 50, label : 'DKEncoder1Backup'},
    DKEncoder2Backup                   :{key : 51, label : 'DKEncoder2Backup'},
    DKNumberOfCorrections              :{key : 52, label : 'DKNumberOfCorrections'},
    DKMaxCorrectionLimitExceeded       :{key : 53, label : 'DKMaxCorrectionLimitExceeded'},
    DKLoadDumpRelayCounter             :{key : 54, label : 'DKLoadDumpRelayCounter'},
    DKLoadDumpUsesInLastRun            :{key : 55, label : 'DKLoadDumpUsesInLastRun'},
    DKL475RunTimerMS                   :{key : 56, label : 'DKL475RunTimerMS'},
    DKF301RunTimerMS                   :{key : 57, label : 'DKF301RunTimerMS'},
    DKRtc                              :{key : 58, label : 'DKRtc'},
    DKTimeSinceLastCorrectionMS        :{key : 59, label : 'DKTimeSinceLastCorrectionMS'},
    DKRoller1                          :{key : 60, label : 'DKRoller1'},
    DKOverload                         :{key : 61, label : 'DKOverload'},
    DKTopFloor                         :{key : 62, label : 'DKTopFloor'},
    DKAutodoorOpenLimit                :{key : 63, label : 'DKAutodoorOpenLimit'},
    DKBottomFloor                      :{key : 64, label : 'DKBottomFloor'},
    DKCurrentFloor                     :{key : 65, label : 'DKCurrentFloor'},
    DKLastKnownFloor                   :{key : 66, label : 'DKLastKnownFloor'},
    DKM1CurrentGoingUp                 :{key : 67, label : 'DKM1CurrentGoingUp'},
    DKM2CurrentGoingUp                 :{key : 68, label : 'DKM2CurrentGoingUp'},
    DKUpJourneyTimeInSeconds           :{key : 69, label : 'DKUpJourneyTimeInSeconds'},
    DKM1CurrentGoingDown               :{key : 70, label : 'DKM1CurrentGoingDown'},
    DKM2CurrentGoingDown               :{key : 71, label : 'DKM2CurrentGoingDown'},
    DKDownJourneyTimeInSeconds         :{key : 72, label : 'DKDownJourneyTimeInSeconds'},
    DKMotor1MaxAdjustInPIUp            :{key : 73, label : 'DKMotor1MaxAdjustInPIUp'},
    DKMotor1MaxAdjustInPIDown          :{key : 74, label : 'DKMotor1MaxAdjustInPIDown'},
    DKMotor2MaxAdjustInPIUp            :{key : 75, label : 'DKMotor2MaxAdjustInPIUp'},
    DKMotor2MaxAdjustInPIDown          :{key : 76, label : 'DKMotor2MaxAdjustInPIDown'},
    DKEncoderCountM1StartOfTrip        :{key : 77, label : 'DKEncoderCountM1StartOfTrip'},
    DKEncoderCountM2StartOfTrip        :{key : 78, label : 'DKEncoderCountM2StartOfTrip'},
    DKEncoderCountM1EndOfTrip          :{key : 79, label : 'DKEncoderCountM1EndOfTrip'},
    DKEncoderCountM2EndOfTrip          :{key : 80, label : 'DKEncoderCountM2EndOfTrip'},
    DKJourneyInEncoderCountsM1         :{key : 81, label : 'DKJourneyInEncoderCountsM1'},
    DKJourneyInEncoderCountsM2         :{key : 82, label : 'DKJourneyInEncoderCountsM2'},
    DKUpJourneysMetMinDistance         :{key : 83, label : 'DKUpJourneysMetMinDistance'},
    DKDownJourneysMetMinDistance       :{key : 84, label : 'DKDownJourneysMetMinDistance'},
    DKChargerStatus                    :{key : 85, label : 'DKChargerStatus'},
    DKChargerError                     :{key : 86, label : 'DKChargerError'},
    DKVoltPostMovement                 :{key : 87, label : 'DKVoltPostMovement'},
    DKVoltAfterRecovery                :{key : 88, label : 'DKVoltAfterRecovery'},
    DKBatteryGainPostMove              :{key : 89, label : 'DKBatteryGainPostMove'},
    DKRS485Error                       :{key : 90, label : 'DKRS485Error'},
    DKEngOverride                      :{key : 91, label : 'DKEngOverride'},
    DKCabOverride                      :{key : 92, label : 'DKCabOverride'},
    DKFloor1MinEnc                     :{key : 93, label : 'DKFloor1MinEnc'},
    DKFloor1MaxEnc                     :{key : 94, label : 'DKFloor1MaxEnc'},
    DKFloor1AvgEnc                     :{key : 95, label : 'DKFloor1AvgEnc'},
    DKFloor2MinEncUp                   :{key : 96, label : 'DKFloor2MinEncUp'},
    DKFloor2MaxEncUp                   :{key : 97, label : 'DKFloor2MaxEncUp'},
    DKFloor2AvgEncUp                   :{key : 98, label : 'DKFloor2AvgEncUp'},
    DKFloor2MinEncDown                 :{key : 99, label : 'DKFloor2MinEncDown'},
    DKFloor2MaxEncDown                 :{key : 100, label : 'DKFloor2MaxEncDown'},
    DKFloor2AvgEncDown                 :{key : 101, label : 'DKFloor2AvgEncDown'},
    DKFloor3MinEnc                     :{key : 102, label : 'DKFloor3MinEnc'},
    DKFloor3MaxEnc                     :{key : 103, label : 'DKFloor3MaxEnc'},
    DKFloor3AvgEnc                     :{key : 104, label : 'DKFloor3AvgEnc'},
    DKOutOfLevelWarningCount           :{key : 105, label : 'DKOutOfLevelWarningCount'},
    DKEmergencyStopFlag                :{key : 106, label : 'DKEmergencyStopFlag'},
    DKLastEncoderDifference            :{key : 107, label : 'DKLastEncoderDifference'},
    DKBVPreHealthCheck                 :{key : 108, label : 'DKBVPreHealthCheck'},
    DKBVPreChargerOff                  :{key : 109, label : 'DKBVPreChargerOff'},
    DKBVPostHealthCheck                :{key : 110, label : 'DKBVPostHealthCheck'},
    DKNoHealthChecksSinceLastRun       :{key : 111, label : 'DKNoHealthChecksSinceLastRun'},
    DKGainOverChargeTime               :{key : 112, label : 'DKGainOverChargeTime'},
    DKLossOverDischargeTime            :{key : 113, label : 'DKLossOverDischargeTime'},
    DKSmokeAlarmActive                 :{key : 114, label : 'DKSmokeAlarmActive'},
    DKSmokeAlarmFinishTravel           :{key : 115, label : 'DKSmokeAlarmFinishTravel'},
    DKLVModeActivationTracker          :{key : 116, label : 'DKLVModeActivationTracker'},
    DKLVModeHardStopFlag               :{key : 117, label : 'DKLVModeHardStopFlag'},
    DKLVFinishTravel                   :{key : 118, label : 'DKLVFinishTravel'},
    DKL475MotorState                   :{key : 119, label : 'DKL475MotorState'},
    DKF301MotorState                   :{key : 120, label : 'DKF301MotorState'},
    DKF301MotorRunning                 :{key : 121, label : 'DKF301MotorRunning'},
    DKAutodoorOpenLimitSwitch          :{key : 122, label : 'DKAutodoorOpenLimitSwitch'},
    DKAutodoorErrorState               :{key : 123, label : 'DKAutodoorErrorState'},
    DKAutodoorErrorCount               :{key : 124, label : 'DKAutodoorErrorCount'},
    DKAutodoorDoorCloseFail            :{key : 125, label : 'DKAutodoorDoorCloseFail'},
    DKAutodoorF1State                  :{key : 126, label : 'DKAutodoorF1State'},
    DKAutodoorF3State                  :{key : 127, label : 'DKAutodoorF3State'},
    DKVoltageReadingLowestValue        :{key : 128, label : 'DKVoltageReadingLowestValue'},
    DKVoltageReadingHighestValue       :{key : 129, label : 'DKVoltageReadingHighestValue'},
    DKLogMessage                       :{key : 130, label : 'DKLogMessage'},
    DKRoller5                          :{key : 131, label : 'DKRoller5'},
} ;

// i want an enum which takes all the values from DiagnosticKeys to create a new object with the keys and values swapped but with the keys as strings prefixed with "k"
// so that i can pass in a key in the form "k<key>" and get a nice label for it based on the DiagnosticKets enum
// i.e. keyLabel("k1") => "L475 Version"
const DiagnosticKeysEnum = Object.keys(DiagnosticKeys).reduce((acc, key) => {
    const newKey = `k${DiagnosticKeys[key].key}` ;
    acc[newKey] = {key: DiagnosticKeys[key].key, label: DiagnosticKeys[key].label, decoder: DiagnosticKeys[key].decoder || null, dk: newKey} ;
    return acc ;
}, {}) ;


const keyDiagnostic = (key) => {
    let diagnostic = null;
    console.log("[keyDiagnostic] find key: ", key, " in DiagnosticKeysEnum: ", DiagnosticKeysEnum);
    if (key in DiagnosticKeysEnum) {
        diagnostic = DiagnosticKeysEnum[key];
    }
    return diagnostic;
}

const keyLabel = (key) => {
    let label = "Unknown";
    if (key in DiagnosticKeysEnum) {
        const originalKey = DiagnosticKeysEnum[key];
        // Remove 'DK' prefix and add spaces before capital letters
        label = originalKey.label.replace(/^DK/, '').replace(/([A-Z])/g, ' $1').trim();
    }
    return label;
}

const keyDiagnosticForKey = (k) => {
    // Check if the key is valid
    return keyDiagnostic(`k${k}`) ;
}

export {
    DiagnosticKeys,
    keyDiagnostic,
    keyLabel,
    keyDiagnosticForKey
}  ;



