import AccessControlled from "../users/AccessControlled";
import React from "react";


const ThreadConfigEditor = ({ threadConfig, setThreadConfig , pcbSim}) => {


    return (
        <div style={{display: 'inline-block', verticalAlign: 'top', margin: '20px'}}>
            <h3>Thread Network Config</h3>

            {(pcbSim === null) &&
                <div style={{padding: '10px', border: '1px solid red', borderRadius: '5px', backgroundColor: 'rgba(255, 0, 0, 0.1)'}}>PCB SIM Number is not available</div>
            }

            {(threadConfig.is_configured === false) &&
                <>
                    <div style={{padding: '10px', border: '1px solid red', borderRadius: '5px', backgroundColor: 'rgba(255, 0, 0, 0.1)'}}>Thread network is not configured.</div>

                    <div style={{paddingTop: '20px'}}>
                        <button className="button-info" style={{display: 'inline-block'}}
                                onClick={() => {
                                }}><span
                            className="material-symbols-sharp"
                            style={{verticalAlign: '-7px', paddingRight: '8px'}}>start</span>Initialise
                        </button>
                        <button className="button-search" style={{display: 'inline-block'}}
                                onClick={() => {
                                }}><span
                            className="material-symbols-sharp"
                            style={{verticalAlign: '-7px', paddingRight: '8px'}}>refresh</span>Refresh
                        </button>

                    </div>
                </>
            }

            {
                (threadConfig.is_configured === true) &&
                <>
                    <div style={{display: 'inline-block', paddingRight: '10px'}}>
                        <div>
                            <div style={{padding: '2px'}}>
                            <div style={{width: '150px', display: 'inline-block'}}>Network Name</div>
                            <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadConfig.network_name}</div>
                        </div>

                    </div>
                    <div>
                        <div style={{padding: '2px'}}>
                            <div style={{width: '150px', display: 'inline-block'}}>Mesh Local Prefix</div>
                            <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadConfig.mesh_local_prefix}</div>
                        </div>

                    </div>
                    <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Network Channel</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadConfig.network_channel}</div>
                            </div>

                        </div>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Network PanID</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadConfig.network_panid.toString(16).toUpperCase()}</div>
                            </div>

                        </div>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Network ExtPanID</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadConfig.network_extpanid.toString(16).toUpperCase()}</div>
                            </div>
                        </div>

                        <AccessControlled admin_only deniedView={<></>}>
                            <div>
                                <div style={{padding: '2px'}}>
                                    <div style={{width: '150px', display: 'inline-block'}}>Network Key</div>
                                    <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadConfig.network_key}</div>
                                </div>

                            </div>
                        </AccessControlled>
                    </div>

                    <div style={{paddingTop: '20px'}}>
                        <button className="button-info" style={{display: 'inline-block'}}
                                onClick={() => {
                                }}><span
                            className="material-symbols-sharp"
                            style={{verticalAlign: '-7px', paddingRight: '8px'}}>edit</span>Edit
                        </button>
                        <button className="button-search" style={{display: 'inline-block'}}
                                onClick={() => {
                                }}><span
                            className="material-symbols-sharp"
                            style={{verticalAlign: '-7px', paddingRight: '8px'}}>refresh</span>Refresh
                        </button>
                        <button className="button-delete" style={{display: 'inline-block'}}
                                onClick={() => {
                                }}><span
                            className="material-symbols-sharp"
                            style={{verticalAlign: '-7px', paddingRight: '8px'}}>reset_settings</span>Factory Reset
                        </button>
                    </div>
                </>
            }


        </div>
    )
}

export default ThreadConfigEditor;