
// ----------------------------------------------------------
// CRC
// ----------------------------------------------------------
const los_fletch = (msg, len) => {
    let sum1 = 0;
    let sum2 = 0;

    if (msg === null) {
        return null;
    }

    for (let i = 0; i < len; i++) {
        sum1 = (sum1 + msg[i]) & 0xFF;
        sum2 = (sum2 + sum1) & 0xFF;
    }

    return [255 - (sum1 + sum2), 255 - (sum1 + (255 - (sum1 + sum2)))];
}

// ----------------------------------------------------------
// Getters
// ----------------------------------------------------------

const los_buf_get_buf = (offset, data, len, maxlen) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: null};
    }

    if (len > maxlen) {
        len = maxlen;
    }

    let result = {offset: offset + len, value: new Uint8Array(len)}
    for (let i = 0; i < len; i++) {
        result.value[i] = data[offset + i];
    }

    return result;
}


const los_buf_get_u8 = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    return {offset: offset + 1, value: data[offset]};
}


const los_buf_get_i16 = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    let val = (data[offset] << 8) | data[offset + 1];
    if (val & 0x8000) {
        val = -(0x10000 - val);
    }
    return {offset: offset + 2, value: val};
}


const los_buf_get_i32 = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    let val = (data[offset] << 24) | (data[offset + 1] << 16) | (data[offset + 2] << 8) | data[offset + 3];
    if (val & 0x80000000) {
        val = -(0x100000000 - val);
    }
    return {offset: offset + 4, value: val};
}


const los_buf_get_i64 = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    let val = (data[offset] << 56) +
        (data[offset + 1] << 48) +
        (data[offset + 2] << 40) +
        (data[offset + 3] << 32) +
        (data[offset + 4] << 24) +
        (data[offset + 5] << 16) +
        (data[offset + 6] << 8) +
        data[offset + 7];

    if (val & 0x8000000000000000) {
        val = -(0x10000000000000000 - val);
    }
    return {offset: offset + 8, value: val};
}


const los_buf_get_u16 = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    let val = ((data[offset] << 8) + data[offset + 1]);
    return {offset: offset + 2, value: val};
}

const los_buf_get_u32 = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    let val = ((data[offset] << 24) + (data[offset + 1] << 16) + (data[offset + 2] << 8) + data[offset + 3]);
    return {offset: offset + 4, value: val};
}


const los_buf_get_u64 = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    // let val = ((data[offset] << 56) +
    //     (data[offset + 1] << 48) +
    //     (data[offset + 2] << 40) +
    //     (data[offset + 3] << 32) +
    //     (data[offset + 4] << 24) +
    //     (data[offset + 5] << 16) +
    //     (data[offset + 6] << 8) +
    //     data[offset + 7]);

    let result = 0n; // Use BigInt for 64-bit arithmetic
    for (let i = 0; i < 8; i++) {
        result = (result << 8n) + BigInt(data[offset + i]);
    }

    return {offset: offset + 8, value: result}
}


const los_buf_get_double = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: 0};
    }

    let p = new Uint8Array(value);
    for (let i = 0; i < 8; i++) {
        p[i] = data[offset + i];
    }

    let dv = new DataView(p.buffer);
    let value = dv.getFloat64(0, true);
    return {offset: offset + 8, value: value};
}

const los_buf_get_str = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: null};
    }

    let length = data[offset];
    let chars = new Array(length);

    // Loop over the bytes of the string
    for (let i = 0; i < length; i++) {
        // Get the character from the byte and add it to the array
        chars[i] = String.fromCharCode(data[offset + i + 1]);
    }

    let value = chars.join('');
    return {offset: offset + length + 1, value: value}
}

const los_buf_get_bool = (offset, data) => {
    if (data === null || offset < 0) {
        return {offset: offset, value: false};
    }

    let val = data[offset] === 1;
    return {offset: offset + 1, value: val};
}

// ----------------------------------------------------------
// Setters
// ----------------------------------------------------------

const los_buf_set_u64 = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = (value >> 56) & 0xFF;
    buffer[nd++] = (value >> 48) & 0xFF;
    buffer[nd++] = (value >> 40) & 0xFF;
    buffer[nd++] = (value >> 32) & 0xFF;
    buffer[nd++] = (value >> 24) & 0xFF;
    buffer[nd++] = (value >> 16) & 0xFF;
    buffer[nd++] = (value >> 8) & 0xFF;
    buffer[nd++] = value & 0xFF;
    return nd;
}

const los_buf_set_u32 = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = (value >> 24) & 0xFF;
    buffer[nd++] = (value >> 16) & 0xFF;
    buffer[nd++] = (value >> 8) & 0xFF;
    buffer[nd++] = value & 0xFF;
    return nd;
}

const los_buf_set_u16 = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = (value >> 8) & 0xFF;
    buffer[nd++] = value & 0xFF;
    return nd;
}

const los_buf_set_i16 = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = (value >> 8) & 0xFF;
    buffer[nd++] = value & 0xFF;
    return nd;
}


const los_buf_set_i32 = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = (value >> 24) & 0xFF;
    buffer[nd++] = (value >> 16) & 0xFF;
    buffer[nd++] = (value >> 8) & 0xFF;
    buffer[nd++] = value & 0xFF;
    return nd;
}

const los_buf_set_i64 = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = (value >> 56) & 0xFF;
    buffer[nd++] = (value >> 48) & 0xFF;
    buffer[nd++] = (value >> 40) & 0xFF;
    buffer[nd++] = (value >> 32) & 0xFF;
    buffer[nd++] = (value >> 24) & 0xFF;
    buffer[nd++] = (value >> 16) & 0xFF;
    buffer[nd++] = (value >> 8) & 0xFF;
    buffer[nd++] = value & 0xFF;
    return nd;
}

const los_buf_set_bool = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = value ? 1 : 0;
    return nd;
}

const los_buf_set_u8 = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    buffer[nd++] = value;
    return nd;
}


const los_buf_set_double = (buffer, nd, value) => {
    if (buffer === null) {
        return nd;
    }

    let p = new Uint8Array(value);
    for (let i = 0; i < 8; i++) {
        buffer[nd++] = p[i];
    }

    return nd;
}

const los_buf_set_str = (buffer, nd, value) => {
    if (value === null) {
        return nd;
    }

    let len = value.length;
    if (len > 255) {
        len = 255;
    }

    buffer[nd++] = len & 0xFF;
    for (let i = 0; i < len; i++) {
        buffer[nd++] = value.charCodeAt(i);
    }
    return nd;
}

const los_buf_set_buf = (buffer, nd, value, len) => {
    if (value === null) {
        return nd;
    }

    for (let i = 0; i < len; i++) {
        buffer[nd++] = value[i];
    }
    return nd;
}


export {
    los_fletch,
    los_buf_get_buf,
    los_buf_get_u8,
    los_buf_get_i16,
    los_buf_get_i32,
    los_buf_get_i64,
    los_buf_get_u16,
    los_buf_get_u32,
    los_buf_get_u64,
    los_buf_get_double,
    los_buf_get_str,
    los_buf_get_bool,
    los_buf_set_u64,
    los_buf_set_u32,
    los_buf_set_u16,
    los_buf_set_i16,
    los_buf_set_i32,
    los_buf_set_i64,
    los_buf_set_bool,
    los_buf_set_u8,
    los_buf_set_double,
    los_buf_set_str,
    los_buf_set_buf
}
