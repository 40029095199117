
import Permissions from "../../Permissions";

export const dolphinHomeRootMenu = [
    {
        key: 'home_search',
        title: 'Search',
        subtitle: 'Find a lift',
        icon: 'search',
        handler: null,
        navigation: '/search',
        is_admin_only: false,
        view_provider: null,
        permission : Permissions.MenuSearch
    },
    {
        key: 'home_cases',
        title: 'Active Events',
        subtitle: 'Manage open events',
        icon: 'work_history',
        handler: null,
        navigation: '/case-events',
        is_admin_only: false,
        view_provider: null,
        permission : Permissions.MenuEvents
    },
    {
        key: 'home_safety',
        title: 'Safety Status',
        subtitle: 'View active safety lifts',
        icon: 'work_history',
        handler: null,
        navigation: '/safety',
        is_admin_only: false,
        view_provider: null,
        permission : Permissions.MenuSafety
    },
    {
        key: 'home_sims',
        title: 'Manage SIMs',
        subtitle: 'SIM information',
        icon: 'sim_card',
        handler: null,
        navigation: '/mobile-data',
        is_admin_only: true,
        view_provider: null,
        permission : Permissions.MenuManageSims
    },
    {
        key: 'home_products',
        title: 'Products',
        subtitle: 'Lift products',
        icon: 'category',
        handler: null,
        navigation: '/products',
        is_admin_only: false,
        view_provider: null,
        permission : Permissions.MenuProducts
    },
    {
        key: 'home_dealers',
        title: 'Dealers',
        subtitle: 'Dealer dashboard',
        icon: 'storefront',
        handler: null,
        navigation: '/dealers',
        is_admin_only: true,
        view_provider: null,
        permission : Permissions.MenuDealers
    },
    {
        key: 'home_customers',
        title: 'Customers',
        subtitle: 'Customer dashboard',
        icon: 'hiking',
        handler: null,
        navigation: '/customers',
        is_admin_only: true,
        view_provider: null,
        permission : Permissions.MenuCustomers
    },
    {
        key: 'home_triggers',
        title: 'Triggers',
        subtitle: 'Event triggers',
        icon: 'insights',
        handler: null,
        navigation: '/triggers',
        is_admin_only: false,
        view_provider: null,
        permission : Permissions.MenuTriggers
    },
    // TODO - add Analytics page back to options when FIXED (data causing errors on load)
    // {
    //     key: 'home_analytics',
    //     title: 'Analytics',
    //     subtitle: 'Data analysis',
    //     icon: 'bar_chart_4_bars',
    //     handler: null,
    //     navigation: '/analytics',
    //     is_admin_only: false,
    //     view_provider: null,
    //     permission : Permissions.MenuAnalytics
    // },
    {
        key: 'home_live',
        title: 'Live Connect',
        subtitle: 'Live lift data',
        icon: 'bluetooth',
        handler: null,
        navigation: '/live-connect',
        is_admin_only: false,
        view_provider: null,
        permission : Permissions.MenuLiveConnect
    },
    {
        key: 'home_new_lift',
        title: 'Add a Lift',
        subtitle: 'New lift installation',
        icon: 'add',
        handler: null,
        navigation: '/create-lift',
        is_admin_only: false,
        view_provider: null,
        permission : Permissions.MenuNewLift
    },
    {
        key: 'home_admin',
        title: 'Admin',
        subtitle: 'User/Group management',
        icon: 'badge',
        handler: null,
        navigation: '/admin',
        is_admin_only: true,
        view_provider: null,
        permission : Permissions.MenuAdmin
    },
    // {
    //     title: 'Commission',
    //     subtitle: 'Installation commissioning',
    //     icon: 'barcode_scanner',
    //     handler: null,
    //     navigation: '/installation',
    //     is_admin_only: false,
    //     view_provider: null
    // }
]