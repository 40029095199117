import {IOGetIfNoPriorChainItem} from "../../domain/IODefinitions";
import {useContext, useEffect, useState} from "react";
import {LiftDataContext} from "./LiftDataNavigatorContext";


export const LiftSafetyChainView = () => {
    const { dataPoint, liftData, liftState, lift} = useContext(LiftDataContext) ;

    const [io, setIO] = useState(null) ;

    useEffect(() => {
        update() ;
    }, [lift]) ;

    const update = () => {
        if (!lift) {
            return ;
        }

        setIO(lift.io_definition) ;
    }

    return (
        <div>
            <h5 style={{textAlign: 'center'}}>Primary Safety Chain</h5>
            {io?.target_io?.primary_safety.map((sc, idx) => {
                let ti = io.def.adc_item_map[sc]
                let last = idx === io.target_io.primary_safety.length - 1
                let tripped = IOGetIfNoPriorChainItem(io, dataPoint, sc)
                let tripStyle = tripped ? "safety-cell-trip" : "safety-cell"
                return (
                    <div key={`sc_${idx}`}>
                        <div className={tripStyle}>{ti?.io_desc || "---"}</div>
                        {!last && <div style={{textAlign: 'center'}} ><span className="material-symbols-outlined" style={{fontSize: '12px', color: 'red'}}>south</span></div>}
                    </div>
                )
            })}
        </div>
    )
}