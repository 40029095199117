import {SimProviderStatusCountsTile} from "./SimProviderStatusCountsTile";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import Sims from "../../api/simApi";
import HttpCodes from "../../library/HttpCodes";
import moment from "moment/moment";
import Helper from "../../library/Helper";
import SimStatusList from "./SimStatusList";
import {AuthContext} from "../../ResponsiveApp";
import LinkBuilder from "../../domain/LinkBuilder";
import {LiftDataViewState} from "../lift/LiftManagementView";
import {NavigationContext} from "../navigation/DolphinNavigator";
import SimProviderStatusOverviewList from "./SimProviderStatusOverviewList";


export const SimManagementPanel = () => {
    const gotopage = useNavigate() ;
    const {authorisation} = useContext(AuthContext)
    const {navigate} = useContext(NavigationContext)

    const [statusCounts, setStatusCounts] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null) ;
    const [error, setError] = useState(null) ;
    const [selectedRow, setSelectedRow] = useState(null) ;

    const [selectedSimProviderState, setSelectedSimProviderState] = useState(null) ;
    const [sims, setSims] = useState([]) ;

    useEffect(() => {
        refreshState() ;
    }, []);

    useEffect(async () => {
        if (selectedRow !== null) {
            console.log("Selected row: ", selectedRow, statusCounts[selectedRow]) ;
            setSelectedSimProviderState(statusCounts[selectedRow]) ;
            await refreshSimSet(statusCounts[selectedRow].status) ;
        }
        else {
            setSelectedSimProviderState(null) ;
            setSims([]) ;
        }
    }, [selectedRow]);

    const refreshState = async () => {
        try {
            let counts = await Sims.getSIMStatusCounts() ;
            if (counts.status === HttpCodes.HttpOK) {
                setStatusCounts(counts.data) ;
                setError(null) ;
            }
            else {
                setError("Unable to refresh status counts")
            }

            setLastUpdated(moment()) ;
        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
        }
        finally {
            setSelectedRow(null) ;
        }
    }

    const refreshSimSet = async (status) => {
        try {
            let simdata = await Sims.getSIMsOfStatus(status)
            if (simdata.status === HttpCodes.HttpOK) {
                setSims(simdata.data) ;
                setError(null) ;
            }
            else {
                setError("Unable to refresh status counts")
            }

        }
        catch(e) {
            console.error(e) ;
            setError("Unable to refresh state")
            setSims([]) ;
        }
    }

    const onSelectSimProvider = async (sim) => {
        console.log("Selected sim provider: ", sim) ;
        await refreshSimSet(sim.status) ;
    }

    const gotoLiftData = (lift) => {
        LinkBuilder.updateDeviceActiveView(lift.device_id, LiftDataViewState.DataStatus)
        navigate(`/lift?device=${lift.device_id}`)
    }

    return (
        <div>
            <SimProviderStatusOverviewList targetSimProviders={statusCounts} onSelectSimProviderStatus={onSelectSimProvider} />

            <div>
            <div className="section-header">Selected SIMs</div>
                <SimStatusList sims={sims} onSelect={(sim) => gotoLiftData(sim)} show_len={30}/>
            </div>
        </div>
    )
}