import jwtDecode from "jwt-decode";



const getAuthorisation = (auth) => {
    let isAdmin = false

    if (auth) {
        const decoded = jwtDecode(auth)
        if (decoded?.account.userid) {
            const {userid, emailaddress, is_admin} = decoded.account

            isAdmin = is_admin

            // console.log("Authorisation: ", decoded)
            return {
                account : decoded.account,
                isAdmin,
                authorised : true
            }
        }
    }

    return {
        account : null,
        isAdmin,
        authorised : false
    }
}

const getPermissions = (auth) => {
    if (auth) {
        const decoded = jwtDecode(auth)
        return decoded?.permissions || {}
    }
    else {
        console.log("No permissions auth")
    }

    return {}
}

const getRoles = (auth) => {
    if (auth) {
        const decoded = jwtDecode(auth)
        return decoded?.roles || {}
    }

    return {}
}

export { getAuthorisation, getPermissions , getRoles}