
const HubStatusView = ({ hubStatus , diagnosticData, pcbSim, pcbImei}) => {

    return (
        <div style={{display: 'inline-block', verticalAlign: 'top', margin: '20px'}}>
            <h3>Hub Status</h3>

            {(!hubStatus) &&
                <>
                    <div style={{padding: '10px', border: '1px solid red', borderRadius: '5px', backgroundColor: 'rgba(255, 0, 0, 0.1)'}}>Hub Status is not available.</div>

                    <div style={{paddingTop: '20px'}}>
                        <button className="button-search" style={{display: 'inline-block'}}
                                onClick={() => {
                                }}><span
                            className="material-symbols-sharp"
                            style={{verticalAlign: '-7px', paddingRight: '8px'}}>refresh</span>Refresh
                        </button>
                    </div>
                </>
            }

            {(hubStatus) &&
                <>
                    <div style={{display: 'inline-block', verticalAlign: 'top', paddingRight: '10px'}}>
                        <h3>Hub Status</h3>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Time Since
                                    Boot (s)
                                </div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{hubStatus?.time_since_boot}</div>
                            </div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Free Heap
                                    Size
                                </div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{hubStatus?.free_heap_size}</div>
                            </div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Task Status
                                </div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{hubStatus?.task_status}</div>
                            </div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Hub Time
                                </div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{hubStatus?.time_string}</div>
                            </div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Hub Revision
                                </div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{hubStatus?.hub_revision}</div>
                            </div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '150px', display: 'inline-block'}}>Hub Released
                                </div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{hubStatus?.hub_released}</div>
                            </div>

                            {(pcbSim) &&
                                <div style={{padding: '2px'}}>
                                    <div style={{width: '150px', display: 'inline-block'}}>PCB SIM
                                    </div>
                                    <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{pcbSim}</div>
                                </div>
                            }

                            {(!pcbSim) &&
                                <div style={{padding: '10px', border: '1px solid red', borderRadius: '5px', backgroundColor: 'rgba(255, 0, 0, 0.1)'}}>PCB SIM is not available.</div>
                            }

                            {(pcbImei) &&
                                <div style={{padding: '2px'}}>
                                    <div style={{width: '150px', display: 'inline-block'}}>PCB IMEI
                                    </div>
                                    <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{pcbImei}</div>
                                </div>
                            }

                            {(!pcbImei) &&
                                <div style={{padding: '10px', border: '1px solid red', borderRadius: '5px', backgroundColor: 'rgba(255, 0, 0, 0.1)'}}>PCB IMEI is not available.</div>
                            }

                        </div>
                    </div>

                    <div style={{paddingTop: '20px'}}>
                        <button className="button-search" style={{display: 'inline-block'}}
                                onClick={() => {
                                }}><span
                            className="material-symbols-sharp"
                            style={{verticalAlign: '-7px', paddingRight: '8px'}}>refresh</span>Refresh
                        </button>
                    </div>
                </>
            }


        </div>
    );
}

export default HubStatusView;