import Helper from "../../library/Helper";
import React, {useContext} from "react";
import {ProductTypes} from "../../domain/SystemTypes";
import duo_logo from "../../assets/Duo-Hybrid-Black.png";
import trio_logo from "../../assets/Trio-Hybrid-Black.png";
import classic_logo from "../../assets/TCL-Black.png";
import {NavigationContext} from "../navigation/DolphinNavigator";


const EventCaseLogListItem = ({ item, index, total_length}) => {
    const { push_sidebar, navigate, nav_set_active, reset_home_sidebar, loading, setLoading} = useContext(NavigationContext)

    const jumpToLiftEvent = (lift_event_id) => {
        console.log("[EventCaseLogListItem] - jumpToLiftEvent - lift_event_id = ", lift_event_id)
        // navigate(`/lift_event/${lift}`)
    }


    if (!item) {
        return null
    }

    return (
        <div className={"list-item"} onClick={() => {}}>
            <div className={"list-body"} >
                <div className={"list-title"}>
                    <div style={{display: 'inline-block', width: '100%'}}>{Helper.dbTimeToDisplayTime(item.log_date)}
                        {(item.lift_event_id) &&
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <span className="material-symbols-outlined" style={{cursor: 'pointer', marginLeft: '10px', verticalAlign: '-6px'}} onClick={() => jumpToLiftEvent(item.lift_event_id)}>jump_to_element</span>
                            </div>
                        }
                    </div>

                </div>
                <div className={"list-summary"}>
                    <div style={{verticalAlign: 'top'}}>
                        <div style={{marginBottom: '10px', marginTop: '10px'}}>{item.log_text}</div>

                        {(item.rule_set_id) &&
                            <>
                                <div style={{marginTop: '5px'}}>Rule: {item.rule_set_name}</div>
                            </>

                        }

                        {(item.log_by) &&
                            <>
                                <div style={{marginTop: '5px'}}>Raised by {item.firstname} {item.surname}</div>
                            </>
                        }

                        {(!item.log_by) &&
                            <>
                                <div style={{marginTop: '5px'}}>Raised by Dolphin System</div>
                            </>
                        }

                    </div>
                </div>
                <div className={"list-footer"}>
                    {(item.event_status) &&
                        <>
                            <div style={{color: 'black', marginTop: '5px'}}>Status: [{item.event_status}] Created: {Helper.dbTimeToDisplayTime(item.event_created_on)}</div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}


export default EventCaseLogListItem;