import {los_buf_get_str, los_buf_get_u16, los_buf_get_u64, los_buf_get_u8} from "./common_extractors";


const hub_diag_decoder = (message) => {
    console.log("HUB DIAG CONTENT: ", message.content)

    let connect_state = los_buf_get_u8(0, message.content) ;
    let board_last_msg_type = los_buf_get_u16(connect_state.offset, message.content) ;
    let board_last_error_code = los_buf_get_u16(board_last_msg_type.offset, message.content) ;
    let board_last_diag_msg_received = los_buf_get_u64(board_last_error_code.offset, message.content) ;
    let board_last_error_time = los_buf_get_u64(board_last_diag_msg_received.offset, message.content) ;
    let diag_data = los_buf_get_str(board_last_error_time.offset, message.content) ;

    let diag = JSON.parse(diag_data.value)

    console.log("HUB DIAGNOSTICS : ", diag)

    return {
        connect_state: connect_state.value,
        board_last_msg_type: board_last_msg_type.value,
        board_last_error_code: board_last_error_code.value,
        board_last_diag_msg_received: board_last_diag_msg_received.value,
        board_last_error_time: board_last_error_time.value,
        diag_data: diag
    }
}


export {
    hub_diag_decoder
}


