import React, {useEffect, useState} from "react";
import CustomerSelectList from "../devices/CustomerSelectList";
import Lifts from "../../api/liftApi";
import {CustomerSearchBuilderView} from "../search/CustomerSearchBuilderView";
import {useNavigate} from "react-router-dom";
import LinkBuilder from "../../domain/LinkBuilder";
import {DealerSearchBuilderView} from "../search/DealerSearchBuilderView";
import DealerSelectList from "./DealerSelectList";
import Helper from "../../library/Helper";


const DealerDetailView = ({dealer, title = "Dealer", allowEdit = true, allowChange = true, onDealerSelected, onSearchStart, onSearchClose}) => {
    const gotopage = useNavigate() ;

    const [selectingDealer, setSelectingDealer] = useState(false) ;
    const [dealers, setDealers] = useState([]) ;

    const edit = () => {
        LinkBuilder.dealer(gotopage, dealer.dealer_id) ;
    }

    const addNew = () => {
        LinkBuilder.dealer(gotopage, "new");
    }

    const onSelectDealer = (c) => {
        setSelectingDealer(false) ;
        if (onDealerSelected) {
            onDealerSelected(c) ;
        }
    }

    const onStartSearchDealer = () => {
        setSelectingDealer(true) ;
        if (onSearchStart) {
            onSearchStart() ;
        }
    }

    const onEndSearchDealer = () => {
        setSelectingDealer(false) ;
        if (onSearchClose) {
            onSearchClose() ;
        }
    }

    const searchResults = (cl) => {
        setDealers(cl || []) ;
    }

    return (
        <div >
            {(selectingDealer) &&
                <div className="lift-header-column" style={{marginTop: '10px'}}>
                    <DealerSearchBuilderView
                        onSearchResults={searchResults}
                        allowAddDealer={true}
                        onClose={onEndSearchDealer}/>

                    {(dealers.length === 0) &&
                        <div>
                            <span>There are no dealers to display</span>
                        </div>
                    }

                    {(dealers.length > 0) &&
                        <DealerSelectList
                            dealers={dealers}
                            onSelect={onSelectDealer}
                        />
                    }
                </div>
            }

            {(!selectingDealer && !dealer) &&
                <div className="lift-header-column" style={{marginTop: '10px'}}>
                    <div className="section-header">Dealer
                        <div style={{display: 'inline-block', float: 'right'}}>
                            {allowEdit && <div className="material-symbols-outlined" onClick={onStartSearchDealer} style={{color: 'blue', cursor: 'pointer', display: 'inline-block', paddingRight: '15px'}}>search</div>}
                            {/*<div className="material-symbols-outlined" onClick={addNew} style={{color: 'blue', cursor: 'pointer', display: 'inline-block', paddingRight: '5px'}}>edit</div>*/}
                        </div>
                    </div>
                    <h5>Not currently assigned to a dealer</h5>
                </div>
            }

            {(!selectingDealer && dealer) &&
                <div>
                    <div className="section-header">{title}
                        <div style={{display: 'inline-block', float: 'right'}}>
                            {allowEdit && <div className="material-symbols-outlined" onClick={onStartSearchDealer} style={{color: 'blue', cursor: 'pointer', display: 'inline-block', paddingRight: '15px'}}>search</div>}
                            {/*<div className="material-symbols-outlined" onClick={addNew} style={{color: 'blue', cursor: 'pointer', display: 'inline-block', paddingRight: '5px'}}>edit</div>*/}
                        </div>
                    </div>
                    <div>

                        <div className="section-edit-row">
                            <label style={{width: '120px', display: 'inline-block'}}>Dealer Name</label>
                            <input type="text"
                                   style={{width: '280px'}}
                                   disabled
                                   placeholder="dealer name"
                                   value={dealer.dealer_name}
                            />
                        </div>
                        <br/>
                        <div className="section-edit-row">
                            <label style={{width: '120px', display: 'inline-block'}}>Contact Phone #</label>
                            <input type="text"
                                   style={{width: '180px'}}
                                   disabled
                                   placeholder="contact phone number"
                                   value={dealer.contact_number}
                            />
                        </div>
                        <br/>
                        <div className="section-edit-row">
                            <label style={{width: '120px', display: 'inline-block', verticalAlign: 'top'}}>Address</label>
                            <div className="non-editable-text" style={{display: 'inline-block'}}>
                                {(dealer.addr_line_1) && <div className="lift-address-text">{dealer.addr_line_1}</div>}
                                {(dealer.addr_line_2) && <div className="lift-address-text">{dealer.addr_line_2}</div>}
                                {(dealer.addr_line_3) && <div className="lift-address-text">{dealer.addr_line_3}</div>}
                                {(dealer.addr_town) && <div className="lift-address-text">{dealer.addr_town}</div>}
                                {(dealer.addr_county) && <div className="lift-address-text">{dealer.addr_county}</div>}
                                {(dealer.addr_postcode) && <div className="lift-address-text">{dealer.addr_postcode}</div>}
                            </div>

                        </div>
                    </div>
                    <br/>
                    <div style={{display: 'inline-block', marginLeft: '120px'}}>
                        <span style={{fontSize: 'x-small', color: 'blue'}}>Last Edited {Helper.dbTimeToDisplayTime(dealer.last_updated)}</span>
                    </div>
                </div>
            }

        </div>

    )

}

export default DealerDetailView;