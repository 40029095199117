import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext, DisplayContext} from "../../ResponsiveApp";
import logo from "../../assets/2020-qa-new-logo-large.svg";
import {NavigationContext} from "./DolphinNavigator";
import {DisplaySizeClass} from "./DisplaySizeClass";
import DolphinSideContextMenu from "./DolphinSideContentMenu";


export const TopMenuContext = createContext();


const DolphinTopMenu = ({children}) => {
    const {authorisation, logout} = useContext(AuthContext);
    const display = useContext(DisplayContext)

    const [shouldDisplayLogo, setShouldDisplayLogo] = useState(true);
    const [activeLogo, setActiveLogo] = useState(logo);
    const [titleBarContent, setTitleBarContent] = useState(null);

    const {sidebarActive, setSidebarActive, home, navigate} = useContext(NavigationContext);

    const topMenuRef = useRef(null);
    const mainContentRef = useRef(null);

    useEffect(() => {
        if (titleBarContent) {
            adjustContentMargin();
        }
    }, [titleBarContent]);

    useEffect(() => {
        adjustContentMargin();
    },[])

    const refreshMenu = () => {
        // console.log("TODO - TopMenuContext: refresh menu");
    }

    const adjustContentMargin = () => {
        const topMenuHeight = topMenuRef.current.offsetHeight;
        mainContentRef.current.style.marginTop = `${topMenuHeight}px`;
    }

    let contentWidth = sidebarActive ? 'calc(100vh - var(--sidebar-width) ' : '100%';
    let contentMarginLeft = sidebarActive ? 'var(--sidebar-width)' : '0px';

    return (
        <TopMenuContext.Provider value={{shouldDisplayLogo, setShouldDisplayLogo, setTitleBarContent, refreshMenu}}>
            <>
                {/* Always show top menu */}
                <div ref={topMenuRef} className={"app-top-menu"}>
                    <div >
                        <div className="app-top-menu-left" style={{marginRight: '20px'}} onClick={() => setSidebarActive(!sidebarActive)}>
                            <div className="icon">
                                <span className="material-symbols-outlined" style={{fontSize: '32px', verticalAlign: '5px', cursor: 'pointer'}}>menu</span>
                            </div>
                        </div>
                        <div className="app-top-menu-left" style={{marginRight: '20px'}} onClick={home}>
                            <div className="icon">
                                <span className="material-symbols-outlined" style={{fontSize: '32px', verticalAlign: '5px', cursor: 'pointer'}}>home</span>
                            </div>
                        </div>

                        {(titleBarContent && titleBarContent.title_icon) &&
                            <div className={"app-menu-logo"}>{titleBarContent.title_icon}</div>
                        }

                        {(display.size_class !== DisplaySizeClass.MobilePortrait) &&
                            <>
                                {(titleBarContent && (titleBarContent.title || titleBarContent.title_view)) &&
                                    <div className="app-top-user-menu">
                                        {titleBarContent.title && <div className="app-top-menu-title">{titleBarContent.title}</div>}
                                        {titleBarContent.title_view && <div className="app-top-menu-title">{titleBarContent.title_view}</div>}
                                    </div>
                                }
                            </>
                        }

                        <div className="app-top-menu-right">
                            <img className="logo"
                                 style={{
                                     width: '100px',
                                     display: 'inline-block',
                                     cursor: 'pointer',
                                 }}
                                 src={logo} alt="Stiltz"/>
                        </div>
                    </div>

                    {(display.size_class === DisplaySizeClass.MobilePortrait && titleBarContent && (titleBarContent.title || titleBarContent.title_view)) &&
                        <div className="app-top-user-menu">
                            {titleBarContent.title && <div className="app-top-menu-title">{titleBarContent.title}</div>}
                            {titleBarContent.title_view && <div className="app-top-menu-title">{titleBarContent.title_view}</div>}
                        </div>
                    }
                </div>

                {/*
                    We have 2 options here.
                    1. If the display size is MobilePortrait, we will display the sidebar menu if the sidebar is active.
                    2. If the display is not MobilePortrait we display the sidebar menu if the sidebar is active and the main content
                */}

                <div ref={mainContentRef} className={"app-container"}>
                    {/* The sidebar itself will decide how to display */}
                    <div className={`${sidebarActive ? '' : 'hide-content'}`}>
                        <DolphinSideContextMenu/>
                    </div>

                    {/* We only display the content if the
                        1. display size is not MobilePortrait
                        2. display size is MobilePortrait and the sidebar is not active
                    */}

                    <div className={`app-main-content ${sidebarActive && display.size_class === DisplaySizeClass.MobilePortrait ? 'hide-content' : ''}`} style={{marginLeft: `${contentMarginLeft}`, width: `${contentWidth}`}}>
                        {children}
                    </div>
                </div>


            </>
        </TopMenuContext.Provider>
    )
}

export default DolphinTopMenu;