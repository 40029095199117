import React, {useEffect, useState} from "react";
import Helper from "../../library/Helper";
import AccessControlled from "./AccessControlled";
import Permissions from "../../Permissions";

const RoleListItem = ({ item , index, total_length, is_selected, onSelect, allowEdit, editing, onEditAction}) => {

    const [dirty, setDirty] = useState(false);
    const [roleName, _setRoleName] = useState("");
    const [roleDescription, _setRoleDescription] = useState("");

    useEffect(() => {
        _setRoleName(item.role_name);
        _setRoleDescription(item.role_description);
        setDirty(false);
    }, [item]);

    const setRoleName = (value) => {
        _setRoleName(value);
        setDirty(value !== item.role_name);
    }

    const setRoleDescription = (value) => {
        _setRoleDescription(value);
        setDirty(value !== item.role_description);
    }

    const saveEdit = () => {
        let edited_role = {...item, role_name: roleName, role_description: roleDescription};
        setDirty(false);
        onEditAction?.({role: edited_role, action: 'SAVE'});
    }

    const cancelEdit = () => {
        setRoleName(item.role_name);
        setRoleDescription(item.role_description);
        setDirty(false);
        onEditAction?.({role: item, action: 'CANCEL'});
    }

    let cn = editing ? "list-item-editing" : (is_selected ? "list-item-selected" : "list-item") ;
    return (
        <div className={cn}>
            <div className={"list-body"} style={{width: 'fit-content'}}>
                <div className={"list-title"} onClick={() => onSelect?.(item)} style={{cursor: 'pointer'}}>
                    <div style={{display: 'inline-block'}}>
                        <span className="material-icons" style={{color: 'var(--std-secondary-blue)', display: 'inline-block', width: '40px'}}>admin_panel_settings</span><span style={{verticalAlign: '4px'}}>{item.role_name}</span>

                    </div>

                </div>
                <div className={"list-summary"}>
                    <div style={{display: 'inline-block'}}>
                    <div>{item.role_description}</div>
                    </div>
                </div>
                <div className={"list-footer"}>
                    {(!editing && onEditAction && allowEdit) && <div style={{display: 'inline-block', cursor: 'pointer'}} onClick={() => onEditAction({role: item, action : 'EDIT'})}><span className="material-icons" style={{}}>edit</span></div>}

                    {(editing) &&
                        <>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Name</label>
                                <input type="text"
                                       style={{width: '280px'}}
                                       placeholder="associated role name"
                                       value={roleName}
                                       onChange={(e) => setRoleName(e.target.value)}
                                />
                            </div>
                            <br/>
                            <div className="section-edit-row">
                                <label style={{width: '140px', display: 'inline-block'}}>Description</label>
                                <input type="text"
                                       style={{width: '280px'}}
                                       placeholder="detailed description"
                                       value={roleDescription}
                                       onChange={(e) => setRoleDescription(e.target.value)}
                                />
                            </div>
                            <br/>


                            {(item.role_id) &&
                                <AccessControlled access={Permissions.AdminViewPermissions}>
                                    <button className={"button-small button-cancel"} onClick={() => onEditAction?.({role: item, action: 'DELETE'})}>
                                        <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>delete</span>Delete
                                    </button>
                                </AccessControlled>
                            }

                            <AccessControlled access={Permissions.AdminViewPermissions}>
                                <button className={"button-small button-cancel"} onClick={cancelEdit}>
                                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>cancel</span>Cancel Changes
                                </button>
                                <button className={"button-small button-save"} disabled={!dirty} onClick={saveEdit}>
                                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>save</span>Save Changes
                                </button>
                            </AccessControlled>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}


export default RoleListItem;