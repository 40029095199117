import {los_buf_get_i64, los_buf_get_str, los_buf_get_u8} from "./common_extractors";
import moment from "moment";


const log_decoder = (message) => {
    // console.log("LOG CONTENT: ", message.content)

    let log_type = los_buf_get_u8(0, message.content) ;
    let log_status = los_buf_get_u8(log_type.offset, message.content) ;
    let log_source = los_buf_get_u8(log_status.offset, message.content) ;
    let log_target = los_buf_get_u8(log_source.offset, message.content) ;
    let log_timestamp = los_buf_get_i64(log_target.offset, message.content) ;
    let log_message = los_buf_get_str(log_timestamp.offset, message.content) ;

    return {
        received : moment(),
        log_type: log_type.value,
        log_status: log_status.value,
        log_source: log_source.value,
        log_target: log_target.value,
        log_timestamp: log_timestamp.value,
        log_message: log_message.value
    }
}


export {
    log_decoder
}


