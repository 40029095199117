import Helper from "../../library/Helper";
import React from "react";
import {ProductTypes} from "../../domain/SystemTypes";
import duo_logo from "../../assets/Duo-Hybrid-Black.png";
import trio_logo from "../../assets/Trio-Hybrid-Black.png";
import classic_logo from "../../assets/TCL-Black.png";


const EventCaseSmallListItem = ({ item, statusIcons , index, total_length, onSelectCase, onSelectLift, onSelectCustomer}) => {

    if (!item) {
        return null
    }

    return (
        <div className={"list-item"} onClick={() => onSelectCase?.(item)}>
            <div className={"list-body"} >
                <div className={"list-title"} >
                    <div>{statusIcons.map((icon, idx) => {return icon})}<span style={{verticalAlign: '4px'}}>[{item.case_status}] {item.case_name}</span></div>
                </div>
                <div className={"list-summary"}>
                    <div style={{verticalAlign: 'top'}}>
                        <div className={"link-button"} style={{marginBottom: '10px', color: 'black'}} >{item.lift_name || "- unnamed -"}</div>


                        {(!item.productname) && <div style={{marginBottom: '10px', color: 'red'}}>No product recorded</div>}
                        {(item.product_type_id === ProductTypes.Duo) && <img className="logo" style={{width: '90px', verticalAlign: 'top'}} src={duo_logo} alt="Stiltz - Product"/>}
                        {(item.product_type_id === ProductTypes.Trio) && <img className="logo" style={{width: '90px', verticalAlign: '-top'}} src={trio_logo} alt="Stiltz - Product"/>}
                        {(item.product_type_id === ProductTypes.Carer) && <img className="logo" style={{width: '120px', verticalAlign: '-top'}} src={classic_logo} alt="Stiltz - Product"/>}
                        {(item.productname) && <div style={{marginBottom: '10px'}}>{item.productname || "- Product Missing -"}</div>}

                    </div>
                </div>
                <div className={"list-footer"}>
                    <div style={{color: 'black', marginTop: '5px'}}>{item.connection_status}</div>
                    <div style={{marginBottom: '10px'}}>Last Data {Helper.dbTimeToDisplayTime(item.last_data_update)}</div>
                </div>
            </div>
            <div className={"list-nav"}>→</div>
        </div>
    )
}


export default EventCaseSmallListItem;