import Users from "../api/userApi";
import moment from "moment";


const _userStore = {
    timestamp : null,
    loading : false,
    users : [],
    userList : []
}

class UserStoreClass {

    refresh = async (force = false) => {
        if (_userStore.loading) {
            return _userStore ;
        }

        if (!force && _userStore.length > 0) {
            return _userStore ;
        }

        try {
            _userStore.loading = true ;
            let userResult = await Users.allUsers() ;
            if (userResult !== null) {
                let users = userResult.data
                let dispprods = users.map((u, oi) => {
                    return {"value": u.userid, "label": `${u.emailaddress}`, "display": `${u.firstname} ${u.surname}`, target: u};
                });

                _userStore.users = users ;
                _userStore.userList = dispprods ;
                _userStore.timestamp = moment.utc().format();
            }
        }
        catch(e) {
            console.trace()
            console.error("Problem loading users - ", e.message);
        }
        finally {
            _userStore.loading = false ;
        }

        return _userStore ;
    }
}

const UserStore = new UserStoreClass();
export default UserStore ;

