import React from "react";


const ThreadStatusView = ({ threadState }) => {

    return (
        <div style={{display: 'inline-block', verticalAlign: 'top', margin: '20px'}}>
            <h3>Thread Network Status</h3>

            {(threadState.is_available === false) &&
                <>
                    <div style={{padding: '10px', border: '1px solid red', borderRadius: '5px', backgroundColor: 'rgba(255, 0, 0, 0.1)'}}>Thread Network Status is not available.</div>
                </>
            }

            {(threadState.is_available === true) &&
                <>
                    <div style={{display: 'inline-block', paddingRight: '10px'}}>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '170px', display: 'inline-block'}}>Network State</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadState?.network_state}</div>
                            </div>

                        </div>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '170px', display: 'inline-block'}}>Mesh Local Address</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadState?.mesh_local_addr}</div>
                            </div>
                        </div>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '170px', display: 'inline-block'}}>Mesh Local Prefix</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadState?.mesh_local_prefix}</div>
                            </div>
                        </div>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '170px', display: 'inline-block'}}>Link Local Address</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadState?.link_local_addr}</div>
                            </div>

                        </div>
                        <div>
                            <div style={{padding: '2px'}}>
                                <div style={{width: '170px', display: 'inline-block'}}>Local Routing Address</div>
                                <div className={"rnd-border-text"} style={{width: 'fit-content', display: 'inline-block'}}>{threadState?.local_routing_addr}</div>
                            </div>

                        </div>
                    </div>

                </>
            }


            <div style={{paddingTop: '20px'}}>
                <button className="button-search" style={{display: 'inline-block'}}
                        onClick={() => {
                        }}><span
                    className="material-symbols-sharp"
                    style={{verticalAlign: '-7px', paddingRight: '8px'}}>refresh</span>Refresh
                </button>
            </div>
        </div>
    );
}

export default ThreadStatusView;