// import duo_logo from "../../assets/Duo-Hybrid-Logo-FC.jpg";
import duo_logo from "../../assets/Duo-Hybrid-Black.png";
// import trio_logo from "../../assets/Trio-Hybrid-Logo-FC.jpg";
import trio_logo from "../../assets/Trio-Hybrid-Black.png";
import {ProductTypes} from "../../domain/SystemTypes";


export const LiftProductTypeLogo = ({product_type_id}) => {
    if (product_type_id === ProductTypes.Duo) {
        return <img className="lv-logo" src={duo_logo} alt="Stiltz - Product" />
    }
    else {
        return <img className="lv-logo"  src={trio_logo} alt="Stiltz - Product" />
    }
}