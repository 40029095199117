import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext, DisplayContext} from "../../ResponsiveApp";
import logo from "../../assets/2020-qa-new-logo-large.svg";
import {NavigationContext} from "./DolphinNavigator";
import {dolphinHomeRootMenu} from "./DolphinHomeRootMenu";
import {DisplaySizeClass} from "./DisplaySizeClass";
import {RoleAccessType} from "../../domain/SystemTypes";




const DolphinSideContextMenu = () => {
    const {authorisation, logout, canAccess, hasMinimumAccess} = useContext(AuthContext)  ;
    const display = useContext(DisplayContext)

    const {sidebarContent, sidebarActive, navigate, menu_hide, activeSideMenuOption} = useContext(NavigationContext) ;
    const [activeMenu, setActiveMenu] = useState([]) ;

    useEffect(() => {
        let a = sidebarContent[0]?.filter((m) => {
            return hasMinimumAccess(m.permission, RoleAccessType.AccessRead)
        })
        setActiveMenu(a)
    }, [sidebarContent])

    const handle_menu = (m) => {
        if (m.navigation) {
            navigate(m.navigation)
        }
        else {
            m.handler?.()
            if (display.size_class === DisplaySizeClass.MobilePortrait) {
                menu_hide()
            }
        }
    }

    let is_sub_menu = sidebarContent.length > 1
    let is_admin = authorisation?.isAdmin || false

    return (
        <div className={`app-sidebar ${display.size_constrained ? 'app-sidebar-full' : ''}`}>
            <div className="app-menu">

                <div className={('home_dash' === activeSideMenuOption) ? "app-menu-item-selected" : "app-menu-item"} onClick={() => navigate("/")}>
                        <span className="material-icons app-menu-icon" style={{
                            verticalAlign: 'center',
                            paddingRight: '8px',
                            fontSize: '42px'
                        }}>home</span>
                    <div className={"app-menu-labels"}>
                        <div className={"app-menu-title"}>Dashboard</div>
                        <div className={"app-menu-desc"}>main dashboard</div>
                    </div>
                </div>


                {activeMenu?.map((item, index) => {
                    if (item.view_provider) {
                        return item.view_provider(item, index)
                    }

                    let cn = item.active ? "app-menu-item-selected" : "app-menu-item"
                    return (
                        <div key={`mn_${index}`} className={cn} onClick={() => handle_menu(item)}>
                                <span className="material-symbols-outlined app-menu-icon" style={{
                                    verticalAlign: 'center',
                                    paddingRight: '8px',
                                    fontSize: '42px'
                                }}>{item.icon}</span>
                            <div className={"app-menu-labels"}>
                                <div className={"app-menu-title"}>{item.title}</div>
                                <div className={"app-menu-desc"}>{item.subtitle || ""}</div>
                            </div>
                        </div>
                    )
                })
                }

                {(!is_sub_menu) &&
                    <>
                        <div className={('home_about' === activeSideMenuOption) ? "app-menu-item-selected" : "app-menu-item"} onClick={() => navigate("/about")}>
                        <span className="material-icons app-menu-icon" style={{
                            verticalAlign: 'center',
                            paddingRight: '8px',
                            fontSize: '42px'
                        }}>info</span>
                            <div className={"app-menu-labels"}>
                                <div className={"app-menu-title"}>About</div>
                                <div className={"app-menu-desc"}>dolphin information</div>
                            </div>
                        </div>

                        <div className={"app-menu-item"} onClick={logout}>
                        <span className="material-icons app-menu-icon" style={{
                            verticalAlign: 'center',
                            paddingRight: '8px',
                            fontSize: '42px',
                            color: 'red'
                        }}>power_settings_new</span>
                            <div className={"app-menu-labels"}>
                                <div className={"app-menu-title"}>Logout</div>
                                <div className={"app-menu-desc"}>quit Dolphin</div>
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>

    )
}

export default DolphinSideContextMenu;