import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext, DisplayContext} from "../../ResponsiveApp";
import DolphinTopMenu, {TopMenuContext} from "./DolphinTopMenu";
import DolphinSideContextMenu from "./DolphinSideContentMenu";
import {dolphinHomeRootMenu} from "./DolphinHomeRootMenu";
import {DisplaySizeClass} from "./DisplaySizeClass";
import Users from "../../api/userApi";
import {LoadingBanner} from "../../library/LoadingBanner";
import {ErrorBanner} from "../../library/ErrorBanner";
import ModelConfirmView from "../../library/ModalConfirmView";


export const NavigationContext = createContext();


const DolphinNavigator = ({children}) => {
    const gotopage = useNavigate() ;
    const location = useLocation()
    const [path, setPath] = useState(location.pathname) ;

    const {authorisation, logout} = useContext(AuthContext)  ;
    const display = useContext(DisplayContext)

    const [loading, setLoading] = useState(null);
    const [sidebarContent, _setSidebarContent] = useState([dolphinHomeRootMenu]) ;
    const [sidebarActive, setSidebarActive] = useState(false) ;
    const [activeSideMenuOption, setActiveSideMenuOption] = useState(null) ;

    const [confirmOptions, setConfirmOptions] = useState(null) ;

    const sidebarContentRef = useRef(sidebarContent) ;
    const setSidebarContent = (content) => {
        _setSidebarContent(content)
        sidebarContentRef.current = content
    }

    useEffect(() => {
        // Update the audit trail whenever the location changes
        let audit_text = location.pathname;

        const save = async () => {
            await save_audit_entry({audit_text : `location: ${audit_text}`})
        }

        setPath(location.pathname)
        save();
    }, [location]);

    const save_audit_entry = async (audit) => {
        try {
            let response = await Users.saveAuditEntry(audit)
            // response ignored
        }
        catch(e) {
            console.error(e)
            // Ignore the error
        }
    }

    const push_sidebar = (m) => {
        setSidebarContent([m, ...sidebarContent])
    }

    const pop_sidebar = () => {
        // remove the first item from the sidebarContent array
        if (sidebarContent.length <= 1) {
            return
        }

        let new_menu = sidebarContent.slice(1)
        setSidebarContent(new_menu)
    }

    const reset_home_sidebar = () => {
        setSidebarContent([dolphinHomeRootMenu])
    }

    const home = () => {
        setSidebarContent([dolphinHomeRootMenu])
        gotopage("/")
    }

    const navigate = (path) => {
        setSidebarContent([dolphinHomeRootMenu])
        if (display.size_class === DisplaySizeClass.MobilePortrait) {
            setSidebarActive(false)
        }
        gotopage(path)
    }

    const menu_hide = () => {
        setSidebarActive(false)
    }

    const menu_show = () => {
        setSidebarActive(true) ;
    }

    const refreshMenu = () => {
        let sb = [...sidebarContentRef.current]
        setSidebarContent(sb)
    }

    const nav_set_active = (menu_item_key) => {
        // set the active menu item
        let sb = [...sidebarContentRef.current]; // create a new copy of the state
        let new_menu = sb[0].map((m) => {
            return {...m, active: m.key === menu_item_key}; // return a new object instead of mutating the existing one
        });

        sb[0] = new_menu;
        setSidebarContent(sb); // set the state with the new object
        setActiveSideMenuOption(menu_item_key)
    }

    const onConfirmed = () => {
        if (confirmOptions.onConfirm) {
            confirmOptions.onConfirm(confirmOptions)
        }
        setConfirmOptions(null)
    }

    const onCancelled = () => {
        if (confirmOptions.onCancel) {
            confirmOptions.onCancel(confirmOptions)
        }
        setConfirmOptions(null)
    }

    return (
        <NavigationContext.Provider value={{
            loading, setLoading,
            path, save_audit_entry,
            sidebarContent, setSidebarContent,
            sidebarActive, setSidebarActive,refreshMenu,
            push_sidebar, pop_sidebar, home, navigate, menu_hide, menu_show, nav_set_active, activeSideMenuOption, reset_home_sidebar,
            confirmOptions, setConfirmOptions
        }}>
            <>
                <div className={"app-root-container"}>

                    <ModelConfirmView
                        message={confirmOptions?.message}
                        title={confirmOptions?.title}
                        onConfirm={onConfirmed}
                        onCancel={onCancelled}
                        open={confirmOptions !== null} />

                    <DolphinTopMenu>
                        <LoadingBanner loading={loading} title={loading} />
                        {/*<ErrorBanner  style={{width: '500px'}} errors={errors} title="Problem loading device/data-points"/>*/}
                        {children}
                    </DolphinTopMenu>
                </div>
            </>
        </NavigationContext.Provider>

    )
}

export default DolphinNavigator;