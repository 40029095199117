import React, {useContext} from "react";
import {DisplayContext} from "../../../ResponsiveApp";
import {LiftDataContext} from "../LiftDataNavigatorContext";
import {LiftPLCDataView} from "../PLC/LiftPLCDataView";


const LiftStatusPLCActiveStatus = () => {
    const display = useContext(DisplayContext)
    const {liftData, lift, liftState, dataPoint} = useContext(LiftDataContext) ;

    return (
        <div className="lv-st">
            <LiftPLCDataView device={lift?.device} datapoint={dataPoint}/>
        </div>
    )
}

export default LiftStatusPLCActiveStatus;