import React, {useEffect, useRef, useState} from "react";


export const DebugBarcodeScanning = () => {

    const [scannerSupported, setScannerSupported] = useState(false);
    const [detector, setDetector] = useState(null);
    const [scanning, setScanning] = useState(false);
    const [scanTimer, setScanTimer] = useState(null);
    // const videoEl = useRef(null);
    const [code, setCode] = useState(null);

    useEffect(() => {
        // check compatibility
        if (!("BarcodeDetector" in window)) {
            console.log("Barcode Detector is not supported by this browser.");
            setScannerSupported(false);
        } else {
            console.log("Barcode Detector supported!");
            setScannerSupported(true);

            // create new detector
            let barcodeDetector = new window.BarcodeDetector({
                formats: ["code_39", "code_128", "ean_13", "ean_8", "qr_code"],
            });

            setDetector(barcodeDetector);

            // check supported types
            window.BarcodeDetector.getSupportedFormats().then((supportedFormats) => {
                supportedFormats.forEach((format) => console.log(format));
            });

        }

    },[])

    const startScan = async () => {
        try {
            setScanning(true);

            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: {
                        ideal: "environment"
                    }
                },
                audio: false
            });

            const videoEl = document.getElementById("scanvideo");
            videoEl.srcObject = stream;
            await videoEl.play();

            let sct = setInterval(async () => {
                const barcodes = await detector.detect(videoEl);
                if (barcodes.length <= 0)
                    return;
                console.log(barcodes.map(barcode => barcode.rawValue));

                setCode(barcodes[0].rawValue);
            }, 1000)

            setScanTimer(sct);
        }
        catch(e) {
            console.error(e);
            setScanning(false);
        }
    }

    const stopScan = () => {
        setScanning(false);
        setCode(null);
        clearInterval(scanTimer);
    }

    return (
        <div>
            <h1>Debug Barcode Scanner</h1>

            {scannerSupported && <div>Scanner is supported</div>}
            {!scannerSupported && <div>Scanner is not supported</div>}

            {(scannerSupported && detector) &&
                <div>
                    <h4>QR Detection is ready</h4>
                    {(!scanning) &&
                        <button className="button-search" onClick={startScan}>
                                        <span className="material-icons" style={{
                                            verticalAlign: '-4px',
                                            paddingRight: '8px',
                                            fontSize: '18px'
                                        }}>qr_code</span>Scan
                        </button>
                    }

                    {(scanning) &&
                        <>
                            <div>
                                <button className="button-search" onClick={stopScan}>
                                    <span className="material-icons" style={{
                                        verticalAlign: '-4px',
                                        paddingRight: '8px',
                                        fontSize: '18px'
                                    }}>qr_code</span>Stop
                                </button>
                                <div>
                                    {code && <div>Code: {code}</div>}
                                    {!code && <div>Scanning...</div>}
                                </div>
                            </div>
                        </>


                    }

                    <video id={"scanvideo"} style={{width: '300px', height: '300px'}}/>
                </div>

            }
        </div>
    )
}