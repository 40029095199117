import {los_buf_get_i32, los_buf_get_i64, los_buf_get_str, los_buf_get_u32, los_buf_get_u64, los_buf_get_u8} from "./common_extractors";


const status_decoder = (message) => {
    console.log("[Status] CONTENT: ", message.content)

    let time_since_boot = los_buf_get_i32(0, message.content) ;
    let free_heap_size = los_buf_get_u32(time_since_boot.offset, message.content) ;
    let task_status = los_buf_get_u32(free_heap_size.offset, message.content) ;
    let time_string = los_buf_get_str(task_status.offset, message.content) ;
    let hub_revision = los_buf_get_str(time_string.offset, message.content) ;
    let hub_released = los_buf_get_str(hub_revision.offset, message.content) ;

    return {
        is_available: true,
        time_since_boot: time_since_boot.value,
        free_heap_size: free_heap_size.value,
        task_status: task_status.value,
        time_string: time_string.value,
        hub_revision: hub_revision.value,
        hub_released: hub_released.value
    }
}


export {
    status_decoder
}


