import React from "react";
import Helper from "../../library/Helper";
import AccessControlled from "./AccessControlled";
import Permissions from "../../Permissions";

const RoleUserListItem = ({ item , index, total_length, onSelect, onRemove, onAssign, editing}) => {

    if (!item) {
        return null
    }

    let cn = item.adding ? "list-item-selected" : "list-item" ;
    return (
        <div className={cn}>
            <div className={"list-body"}  style={{minWidth: '330px'}}>
                <div className={"list-title"} onClick={() => onSelect?.(item)} style={{cursor: 'pointer'}}>
                    {item.is_admin && <div><span className="material-icons" style={{color: 'var(--std-secondary-blue)', display: 'inline-block', width: '40px'}}>admin_panel_settings</span><span style={{verticalAlign: '4px'}}>{item.firstname}&nbsp;{item.surname}</span></div>}
                    {(!item.is_admin) && <div>{item.firstname}&nbsp;{item.surname}</div>}
                </div>
                <div className={"list-summary"}>
                    <div style={{display: 'inline-block'}}>
                        <span className="material-icons" style={{color: 'var(--std-primary-green)', display: 'inline-block', width: '40px'}}>email_disabled</span>
                        <span style={{verticalAlign: '8px'}}>{item.emailaddress}</span>
                    </div>
                </div>
                <div className={"list-footer"}>
                    <div>
                        Created: {Helper.dbDateToDisplayTime(item.created)}
                        <span style={{float: 'right'}}>Last Login: {Helper.dbDateToDisplayTime(item.last_login)}</span>
                    </div>
                    <div style={{paddingTop: '10px', fontSize: '10px'}}>
                        Effective From: {Helper.dbDateToDisplayTime(item.effective_from)} to {Helper.dbDateToDisplayTime(item.effective_to, "unlimited")}
                    </div>
                </div>

                {(editing) &&
                    <>
                        {(item.allocated) &&
                            <AccessControlled access={Permissions.AdminViewPermissions}>
                                <button className={"button-small button-cancel"} onClick={() => onRemove?.(item)}>
                                    <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>delete</span>Remove
                                </button>
                            </AccessControlled>
                        }

                        {(!item.allocated) &&
                            <>

                                {(!item.adding) &&
                                    <AccessControlled access={Permissions.AdminViewPermissions}>
                                        <button className={"button-small button-save"} onClick={() => onAssign?.(item, true)}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>add</span>Assign
                                        </button>
                                    </AccessControlled>
                                }

                                {(item.adding) &&
                                    <AccessControlled access={Permissions.AdminViewPermissions}>
                                        <button className={`button-small button-cancel`} onClick={() => onAssign?.(item, false)}>
                                            <span className="material-icons" style={{verticalAlign: '-6px', paddingRight: '8px'}}>cancel</span>Cancel allocation
                                        </button>
                                    </AccessControlled>
                                }

                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}


export default RoleUserListItem;