import Helper from "../../library/Helper";
import React from "react";
import {ProductTypes} from "../../domain/SystemTypes";
import duo_logo from "../../assets/Duo-Hybrid-Black.png";
import trio_logo from "../../assets/Trio-Hybrid-Black.png";
import classic_logo from "../../assets/TCL-Black.png";


const CustomerSmallListItem = ({ item, index, total_length, onSelect}) => {

    if (!item) {
        return null
    }

    return (
        <div className={"list-item"} onClick={() => onSelect?.(item)}>
            <div className={"list-body"} >
                <div className={"list-title"} >
                    <div>{item.netsuite_customer_id ? `[${item.netsuite_customer_id}]` : ''} {item.contact_name}</div>
                </div>
                <div className={"list-summary"}>
                    {(item.addr_line_1) && <div className="lift-address-text">{item.addr_line_1}</div>}
                    {(item.addr_line_2) && <div className="lift-address-text">{item.addr_line_2}</div>}
                    {(item.addr_line_3) && <div className="lift-address-text">{item.addr_line_3}</div>}
                    {(item.addr_town) && <div className="lift-address-text">{item.addr_town}</div>}
                    {(item.addr_county) && <div className="lift-address-text">{item.addr_county}</div>}
                    {(item.addr_postcode) && <div className="lift-address-text">{item.addr_postcode}</div>}
                    <br/>

                </div>
                <div className={"list-footer"}>
                    <div style={{color: 'black', marginTop: '5px'}}>Install Count {item.installcount}</div>
                    <div style={{marginBottom: '10px'}}>Created {Helper.dbTimeToDisplayTime(item.created)}</div>
                    <div style={{marginBottom: '10px'}}>Last Updated {Helper.dbTimeToDisplayTime(item.last_updated)}</div>
                </div>
            </div>
            <div className={"list-nav"}>→</div>
        </div>
    )
}


export default CustomerSmallListItem;