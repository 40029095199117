import React, {useContext} from "react";
import {DisplayContext} from "../ResponsiveApp";
import DisplayTable from "./DisplayTable";


const AdaptableList = ({listItemBuilder, data, columns, rowProps, displayLength , onFilterRequest = null, onExportRequest = null, filterOpen, showPagination= true, exportData, toggleFilter}) => {
    const display = useContext(DisplayContext)


    return (
        <>
            {(display.size_constrained) &&
                <div >
                    {(listItemBuilder && data && data.length > 0) &&
                        <>
                            {data.map((item,idx) => {
                                return (listItemBuilder(item, idx, data.length))
                            })}
                        </>
                    }
                </div>
            }

            {(!display.size_constrained) &&
                <DisplayTable
                    showPagination={showPagination}
                    displayLength={displayLength}
                    columns={columns}
                    data={data}
                    rowProps={rowProps}
                    filterOpen={filterOpen}
                    onExportRequest={exportData}
                    onFilterRequest={toggleFilter}/>

            }

        </>
    );
}

export default AdaptableList;