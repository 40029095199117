import React, {useContext, useEffect} from "react";
import {DisplayContext} from "../../../ResponsiveApp";
import {LiftDataContext} from "../LiftDataNavigatorContext";
import LiftKeyStatusView from "../safety_icons/LiftKeyStatusView";
import LiftEStopStatusView from "../safety_icons/LiftEStopStatusView";
import LiftBalanceStatusView from "../safety_icons/LiftBalanceStatusView";
import LiftLightGuardStatusView from "../safety_icons/LiftLightGuardStatusView";
import LiftTravelDirectionStatusView from "../safety_icons/LiftTravelDirectionStatusView";
import LiftDataStatusView from "../LiftDataStatusView";
import LiftDoorLockStatusView from "../LiftDoorLockStatusView";
import LiftDoorOpenStatusView from "../LiftDoorOpenStatusView";
import LiftOverloadStatusView from "../LiftOverloadStatusView";
import LiftChargerStatusView from "../LiftChargerStatusView";
import LiftPCBStatusView from "../LiftPCBStatusView";
import LiftWifiStatusView from "../LiftWifiStatusView";
import LiftRuntimeStatusView from "../LiftRuntimeStatusView";
import LiftBatteryStatusView from "../gauges/LiftBatteryStatusView";
import LiftWeightGauge from "../gauges/LiftWeightGauge";
import LiftAngleGauge from "../gauges/LiftAngleGauge";
import {LiftSafetyChainView} from "../LiftSafetyChainView";
import LiftRemoteView from "../LiftRemoteView";
import LiftCopStatusView from "../LiftCopStatusView";
import LiftShaftView from "../LiftShaftView";


const LiftStatusPCBActiveStatus = () => {
    const display = useContext(DisplayContext)
    const { dataViewSet, liftData, liftState, lift} = useContext(LiftDataContext) ;

    let device = lift?.device;
    return (
        <div className="lv-st">
            {(dataViewSet.current) &&
                <>
                    <div className="lv-st-1">
                        <div style={{textAlign: 'left'}}>
                            <h4>Safety Signals</h4>
                            <div style={{display: 'inline-block'}}><LiftKeyStatusView lift_status={liftState}/>
                            </div>
                            <div style={{display: 'inline-block'}}><LiftEStopStatusView  lift_status={liftState}/></div>
                            <div style={{display: 'inline-block'}}><LiftBalanceStatusView  lift_status={liftState}/></div>
                            <div style={{display: 'inline-block'}}><LiftLightGuardStatusView  lift_status={liftState}/></div>
                            <div style={{display: 'inline-block'}}><LiftTravelDirectionStatusView  lift_status={liftState}/></div>
                        </div>
                        {dataViewSet.current.common_safety_chain_desc && <div><span className="material-icons" style={{
                            verticalAlign: '-6px',
                            paddingRight: '8px',
                            color: 'red',
                            fontSize: '48px'
                        }}>notifications_active</span>{dataViewSet.current.common_safety_chain_desc}</div>}
                        {dataViewSet.current.up_safety_chain_desc && <div><span className="material-icons" style={{
                            verticalAlign: '-6px',
                            paddingRight: '8px',
                            color: 'red'
                        }}>notifications_active</span>{dataViewSet.current.up_safety_chain_desc}</div>}
                        {dataViewSet.current.down_safety_chain_desc && <div><span className="material-icons" style={{
                            verticalAlign: '-6px',
                            paddingRight: '8px',
                            color: 'red'
                        }}>notifications_active</span>{dataViewSet.current.down_safety_chain_desc}</div>}

                        <hr/>
                        <div style={{textAlign: 'left'}}>
                            <h4>Information Signals</h4>

                            <div style={{display: 'inline-block'}}><LiftDataStatusView lift_status={liftState}/>
                            </div>
                                <div style={{display: 'inline-block'}}><LiftDoorLockStatusView  lift_status={liftState}/></div>
                                <div style={{display: 'inline-block'}}><LiftDoorOpenStatusView  lift_status={liftState}/></div>
                                <div style={{display: 'inline-block'}}><LiftOverloadStatusView  lift_status={liftState}/></div>
                                <div style={{display: 'inline-block'}}><LiftChargerStatusView   lift_status={liftState}/></div>
                                <div style={{display: 'inline-block'}}><LiftPCBStatusView lift_status={liftState}/>
                            </div>
                            <div style={{display: 'inline-block'}}><LiftWifiStatusView lift_status={liftState}/>
                            </div>
                        </div>

                        <hr/>
                        <div style={{marginTop: '10px'}}>
                            <h4>Firmware Revisions</h4>
                            <div>
                                <div style={{
                                    fontSize: '12px',
                                    display: 'inline-block',
                                    marginRight: '25px'
                                }}>L475 version : <b>{device?.l475_fw_version}</b></div>
                                <div style={{
                                    fontSize: '12px',
                                    display: 'inline-block',
                                    marginRight: '25px'
                                }}>F301 version : <b>{device?.f301_fw_version}</b></div>
                                <div style={{
                                    fontSize: '12px',
                                    display: 'inline-block',
                                    marginRight: '25px'
                                }}>Level Sensor : <b>{device?.level_sensor_fw_version}</b></div>
                                <div style={{
                                    fontSize: '12px',
                                    display: 'inline-block',
                                    marginRight: '25px'
                                }}>Sliding Pot : <b>{device?.sliding_pot_fw_version}</b></div>
                            </div>
                        </div>
                        <hr/>
                        <LiftRuntimeStatusView lift_status={liftState}/>
                        <hr/>

                    </div>
                    <div className="lv-st-2">
                        <div style={{display: 'inline-block', verticalAlign: 'top', textAlign: 'center'}}>
                            <div>
                                <div style={{margin: '5px', fontWeight: 'bold', fontSize: '12px'}}>Battery</div>
                                <LiftBatteryStatusView lift_status={liftState}/>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'inline-block'}}><LiftWeightGauge />
                            </div>
                            <div style={{display: 'inline-block'}}><LiftAngleGauge />
                            </div>
                        </div>

                        <LiftSafetyChainView />
                    </div>
                    <div className="lv-st-3">
                        <h4>Controls</h4>
                        <LiftRemoteView lift_status={liftState}/>
                        <LiftCopStatusView lift_status={liftState}/>
                    </div>
                    <div className="lv-st-4">
                        <LiftShaftView lift_status={liftState}/>
                    </div>
                </>
            }
        </div>

    )
}

export default LiftStatusPCBActiveStatus;