import {los_buf_get_buf, los_buf_get_i64, los_buf_get_str, los_buf_get_u16, los_buf_get_u64, los_buf_get_u8} from "./common_extractors";
import moment from "moment";
import {bytes_to_hex} from "./common_decode";


const thread_nwk_decoder = (message) => {
    // console.log("THREAD NETWORK CONTENT: ", message.content)

    /*
          uint16_t idx = los_buf_set_str(ble_nwk_config_msg.message, 0, app_globals.network_state) ;
            idx = los_buf_set_str(ble_nwk_config_msg.message, idx, app_globals.node_ipv6_addresses.mesh_link_local_str) ;
            idx = los_buf_set_str(ble_nwk_config_msg.message, idx, app_globals.node_ipv6_addresses.mesh_routing_local_addr_str) ;
            idx = los_buf_set_str(ble_nwk_config_msg.message, idx, app_globals.node_ipv6_addresses.mesh_local_addr_str) ;
            idx = los_buf_set_str(ble_nwk_config_msg.message, idx, app_globals.node_ipv6_addresses.mesh_local_prefix_str) ;
     */
    let network_state = los_buf_get_str(0, message.content) ;
    let mesh_link_local = los_buf_get_str(network_state.offset, message.content) ;
    let mesh_routing_local = los_buf_get_str(mesh_link_local.offset, message.content) ;
    let mesh_local_addr = los_buf_get_str(mesh_routing_local.offset, message.content) ;
    let mesh_local_prefix = los_buf_get_str(mesh_local_addr.offset, message.content) ;

    return {
        received : moment(),
        is_available: true,
        network_state: network_state.value,
        link_local_addr: mesh_link_local.value,
        local_routing_addr: mesh_routing_local.value,
        mesh_local_addr: mesh_local_addr.value,
        mesh_local_prefix: mesh_local_prefix.value
    }
}

const thread_config_decoder = (message) => {
    // console.log("THREAD CONFIG CONTENT: ", message.content)

    /*
    uint16_t idx = los_buf_set_u8(ble_nwk_config_msg.message, 0, app_thread_network.is_configured ? 0x01 : 0x00) ;
    idx = los_buf_set_str(ble_nwk_config_msg.message, idx, app_globals.network_name) ;
    idx = los_buf_set_u8(ble_nwk_config_msg.message, idx, app_thread_network.channel) ;
    idx = los_buf_set_u16(ble_nwk_config_msg.message, idx, app_thread_network.udp_port) ;
    idx = los_buf_set_u16(ble_nwk_config_msg.message, idx, app_thread_network.pan_id) ;
    idx = los_buf_set_u64(ble_nwk_config_msg.message, idx, app_thread_network.ext_pan_id) ;
    idx = los_buf_set_str(ble_nwk_config_msg.message, idx, app_thread_network.mesh_local_prefix) ;
    idx = los_buf_set_buf(ble_nwk_config_msg.message, idx, app_thread_network.network_key, 16) ;
 */

    let is_configured = los_buf_get_u8(0, message.content) ;
    let network_name = los_buf_get_str(is_configured.offset, message.content) ;
    let network_channel = los_buf_get_u8(network_name.offset, message.content) ;
    let udp_port = los_buf_get_u16(network_channel.offset, message.content) ;
    let network_panid = los_buf_get_u16(udp_port.offset, message.content) ;
    let network_extpanid = los_buf_get_u64(network_panid.offset, message.content) ;
    let mesh_local_prefix = los_buf_get_str(network_extpanid.offset, message.content) ;
    let network_key = los_buf_get_buf(mesh_local_prefix.offset, message.content, 16, 16) ;

    return {
        received : moment(),
        is_available: true,
        is_configured: !!is_configured.value,
        network_name: network_name.value,
        network_channel: network_channel.value,
        udp_port: udp_port.value,
        network_panid: network_panid.value,
        network_extpanid: network_extpanid.value,
        mesh_local_prefix: mesh_local_prefix.value,
        network_key: bytes_to_hex(network_key.value)
    }
}


export {
    thread_nwk_decoder,
    thread_config_decoder
}


